import Settings from '@libs/Settings';

import moment, { Moment } from 'moment';

class Utils {
	public static round(date: Moment, duration: any) {
		return moment(Math.round((+date) / (+duration)) * (+duration));
	}

	public static calculTimeTrip(date: string, startHours: Moment, endHours: Moment) {
		const settings = Settings.getInstance().get('AUTOPROG') as any;

		const startMorningDay = moment(`${date} ${settings.hours.morning.start}`, 'DD/MM/YYYY HH:mm');
		const endMorningDay = moment(`${date} ${settings.hours.morning.end}`, 'DD/MM/YYYY HH:mm');
		const startAfternoonDay = moment(`${date} ${settings.hours.afternoon.start}`, 'DD/MM/YYYY HH:mm');
		const endAfternoonDay = moment(`${date} ${settings.hours.afternoon.end}`, 'DD/MM/YYYY HH:mm');

		/**
		 *       |                   |
		 *  -----|-###-|-------|-----|-----
		 *       |                   |
		*/
		if (startMorningDay.isSameOrBefore(startHours) && endHours.isSameOrBefore(endMorningDay)) {
			return 0;
		}

		/**
		 *       |                   |
		 *  -----|-----|-------|-###-|-----
		 *       |                   |
		*/
		if (startAfternoonDay.isSameOrBefore(startHours) && endHours.isSameOrBefore(endAfternoonDay)) {
			return 0;
		}

		/**
		 *       |                   |
		 *  -----|-----|-------|-----|-###-
		 *       |                   |
		*/
		if (endAfternoonDay.isSameOrBefore(startHours)) {
			return endHours.diff(startHours, 'minute');
		}

		/**
		 *       |                   |
		 *  -###-|-----|-------|-----|-----
		 *       |                   |
		*/
		if (endHours.isSameOrBefore(startMorningDay)) {
			return endHours.diff(startHours, 'minute');
		}

		/**
		 *       |                   |
		 *  ---##|#----|-------|-----|-----
		 *       |                   |
		*/
		if (startHours.isSameOrBefore(startMorningDay) && startMorningDay.isSameOrBefore(endHours)) {
			return startMorningDay.diff(startHours, 'minute');
		}

		/**
		 *       |                   |
		 *  -----|-----|-------|----#|##---
		 *       |                   |
		*/
		if (startHours.isSameOrBefore(endAfternoonDay) && endAfternoonDay.isSameOrBefore(endHours)) {
			return endHours.diff(endAfternoonDay, 'minute');
		}

		/**
		 *       |                   |
		 *  -----|-----|--###--|-----|-----
		 *       |                   |

		if (endMorningDay.isSameOrBefore(startHours) && endHours.isSameOrBefore(endAfternoonDay)) {
			return endHours.diff(startHours, 'minute');
		}*/

		/**
		 *       |                   |
		 *  -----|----#|##-----|-----|-----
		 *       |                   |

		if (startHours.isSameOrBefore(endMorningDay) && endMorningDay.isSameOrBefore(endHours)) {
			return endHours.diff(endMorningDay, 'minute');
		}*/

		/**
		 *       |                   |
		 *  -----|-----|-----##|#----|-----
		 *       |                   |
		if (startHours.isSameOrBefore(startAfternoonDay) && startAfternoonDay.isSameOrBefore(endHours)) {
			return startAfternoonDay.diff(endAfternoonDay, 'minute');
		}*/

		return 0;
	}

	public static convertMinToString(number: number) {
		const hours = Math.floor(number / 60);
		const minute = number % 60;

		return hours + 'h ' + (minute ? ('00' + minute).slice(-2) : '');
	}
}

export default Utils;

class UtilsRecap {
	public static roundTime(value: number) {
		let hours = Math.floor(value / 60);
		let minute = value % 60;

		if (minute >= 0 && minute < 15) {
			minute = 0;
		}

		if (minute >= 15 && minute < 45) {
			minute = 30;
		}

		if (minute >= 45 && minute < 60) {
			minute = 0;
			hours++;
		}

		return hours * 60 + minute;
	}
}

export default UtilsRecap;

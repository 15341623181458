
import C_Database from '@js/controllers/Database';

class Badges extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Badges',
			database: 'badges'
		});
	}
}

export default Badges;

import GenericService from '../GenericService';

class QrCodeUserService extends GenericService {
	private static instance: QrCodeUserService | null = null;

	constructor() {
		super('qrcode_users');
	}

	public static getInstance(): QrCodeUserService {
		if (!QrCodeUserService.instance) {
			QrCodeUserService.instance = new QrCodeUserService();
		}

		return QrCodeUserService.instance;
	}
}

export default QrCodeUserService;


import Utils from '@libs/utils/Utils';

import C_Database from '@js/controllers/Database';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

import ConfigThermo2 from './ConfigThermo';

class ConfigThermo extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Config Thermo',
			database: 'config_thermo'
		});

		const N_add = document.querySelector('#add') as HTMLButtonElement;

		const canceller = N_add.addEventListener2('click', () => {
			ConfigThermo2.open(null);
		});

		this.cancellers.push(canceller);
	}

	protected buttonEdit(params: any) {
		ConfigThermo2.open(params.data._id);
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Config personnelle'
						}
					]
				}
			]
		];
	}
}

export default ConfigThermo;

export default {
	bills: 'BILLS._CUSTOMERS',
	'bills-providers': 'BILLS._PROVIDERS',
	brands: 'PRODUCTS._BRANDS',
	categories: 'PRODUCTS._CATEGORIES',
	'commands-customer': 'ORDERS._CUSTOMERS',
	'commands-provider': 'ORDERS._PROVIDERS',
	customers: 'CUSTOMERS',
	'customers-address': 'CUSTOMERS._ADDRESS',
	'customers-contacts': 'CUSTOMERS._CONTACTS',
	deliveries: 'DELIVERIES',
	interventions: 'INTERVENTIONS',
	products: 'PRODUCTS',
	'products-groups': 'PRODUCTS._GROUPS',
	'products-provider': 'PRODUCTS._PROVIDERS',
	'products-stocks': 'PRODUCTS._STOCKS',
	providers: 'PROVIDERS',
	'providers-address': 'PROVIDERS._ADDRESS',
	'providers-contacts': 'PROVIDERS._CONTACTS',
	quotes: 'QUOTES',
	'quotes-favorite': 'QUOTES._FAVORITE',
	'quotes-to-do': 'QUOTES._QUOTES_TO_DO',
	receipts: 'RECEIPTS',
	sites: 'SITES',
	'sites-contacts': 'SITES._CONTACTS',
	stock: 'STOCK._STOCKS',
	stock_events: 'STOCK._STOCK_EVENTS',
	transporters: 'TRANSPORTERS',
	users: 'MAIN_SETTINGS.USERS_SYSTEM.USERS',
	config_thermo: 'CONFIG_THERMO',
	qrcode_codes: 'QRCODES',
	qrcode_users: 'QRCODES._USERS',
	badges: 'BADGES'
} as { [key: string]: string };

const template = (item: { [key: string]: any }) => {
	return `

		<div class="box-title">

			${item.name}

			<button class="btn-transparent btn-rounded dropdown d-inline-flex ml-auto">
				<ap-icon name="more/fill" data-toggle="dropdown"></ap-icon>
				<div class="dropdown-menu dropdown-menu-right" style="border-radius:5px">
					<div class="dropdown-item py-2 px-3 cursor-pointer" id="edit">
						<ap-icon name="edit-box/line"></ap-icon>
						Modifier
					</div>
					<div class="dropdown-item py-2 px-3 cursor-pointer" id="duplicate">
						<ap-icon name="file-copy/line"></ap-icon>
						Dupliquer
					</div>
					<div class="dropdown-item py-2 px-3 cursor-pointer" id="delete">
						<ap-icon name="delete-bin/line" class="fill-red"></ap-icon>
						Supprimer
					</div>
				</div>
			</button>

		</div>

		<div class="box-config">
			<div class="d-flex mr-2">
				<div class="page-card-label mr-1 text-nowrap">Module de tete :</div>
				<div class="page-card-value" id="nb-tete">0</div>
			</div>
			<div class="d-flex mr-2">
				<div class="page-card-label mr-1 text-nowrap">Module d'extension :</div>
				<div class="page-card-value" id="nb-ext">0</div>
			</div>
			<div class="d-flex">
				<div class="page-card-label mr-1 text-nowrap">Nombre sondes :</div>
				<div class="page-card-value" id="nb-sonde">0</div>
			</div>
		</div>

		<div class="box-description">
			<span class="page-card-label">Description :</span>
			<span>${item.description}</span>
		</div>
    `;
};

export default template;


import { Tab } from '@autoprog/core-client';

import moment from 'moment';

import Utils from '@libs/utils/Utils';

import M_modal from '../modals/Holidays';

import S_Holidays from '@services/Hours/HolidaysService';
class HolidaysMobileTabs extends Tab {
	private el: HTMLElement;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.init();

		const N_add = this.el.querySelector('#add') as HTMLButtonElement;

		N_add.addEventListener2('click', () => {
			new M_modal().open().then(async () => {
				this.init();
			});
		});
	}

	private async init() {
		const data = await S_Holidays.getInstance().getLastByDayAndUser(Utils.userID);

		const N_list = this.el.querySelector('#list-holidays') as HTMLElement;

		N_list.innerHTML = '';
		for (const item of data) {
			const N_div = document.createElement('div');
			N_div.classList.add('card-mobile');

			if (item.infos.startDate) {
				item.infos.startDate = moment(item.infos.startDate, 'x').format('DD/MM/YYYY');
			}

			if (item.infos.endDate) {
				item.infos.endDate = moment(item.infos.endDate, 'x').format('DD/MM/YYYY');
			}

			const type = ({
				0: 'Congé',
				1: 'Récup\'',
				2: 'Absence',
				3: 'Arrêt Maladie'
			} as { [key: string]: any })[item.infos.type];

			const state = ({
				0: 'En attente',
				1: 'Validé',
				2: 'Validé sous conditions',
				3: 'Refusé'
			} as { [key: string]: any })[item.infos.state];

			N_div.innerHTML = `
                <div class="w-100 text-md">
                    <div>
                        ${type || '&nbsp;'} - ${item.infos.type === '3' ? 'Envoyé' : (item.cancel ? 'Annulé' : state)}
                    </div>
                    <div class="text-muted text-sm">
                        ${item.infos.startDate || '&nbsp;'} ${item.infos.startHours} - ${item.infos.endDate || '&nbsp;'} ${item.infos.endHours}
                    </div>
                </div>
            `;

			N_list.appendChild(N_div);
		}
	}
}

export default HolidaysMobileTabs;

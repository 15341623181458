
class TVA extends HTMLElement {
	public static readonly tagName: string = 'ap-tva';

	public static data: { [key: string]: any }[] = [{
		text: '20',
		value: '20'
	}, {
		text: '10',
		value: '10'
	}, {
		text: '5,5',
		value: '5.5'
	}, {
		text: '2,1',
		value: '2.1'
	}, {
		text: 'Non soumis à la TVA',
		value: '-1'
	}];

	public async connectedCallback() {
		const name = this.getAttribute('name');
		const required = this.getAttribute('required');

		this.classList.add('w-100');

		this.innerHTML = `
            <div class="input-group">
                <select name="${name}" class="form-control text-right" ${required ? 'required' : ''}>
                    ${this.getOptions()}
                </select>
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
        `;

		this.setAttribute('name', '');
	}

	private getOptions() {
		let html = '';

		for (const item of TVA.data) {
			html += `<option value="${item.value}">${item.text}</option>`;
		}

		return html;
	}

	public static getDataToQuote() {
		const tva: { [key: string]: string }[] = [];

		for (const item of TVA.data) {
			tva.push({
				id: item.value,
				text: item.text
			});
		}

		return tva;
	}

	public static getDataToBill() {
		const tva: { [key: string]: string }[] = [];

		for (const item of TVA.data) {
			tva.push({
				id: item.value,
				text: item.text
			});
		}

		return tva;
	}

	public static convertValueToCalc(value: string) {
		value = value || '20';
		value = value.toString();

		if (value === '-1') {
			return 0;
		}

		return Number(value);
	}

	public static convertValue(value: string) {
		value = (value || '').toString();

		for (const item of TVA.data) {
			if (item.value === value) {
				let text = item.text;

				if (value !== '-1') {
					text += ' %';
				}

				return {
					id: item.value,
					text
				};
			}
		}

		return {
			id: value,
			text: value
		};
	}

	public static register() {
		customElements.define(TVA.tagName, TVA);
	}
}

export default TVA;

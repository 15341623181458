
import { Form, Modal } from '@autoprog/core-client';

import T_modal from '../../tpl/modals/settingsHours.html';

import Settings from '@libs/Settings';

import Utils from '@libs/utils/Utils';

class SettingsHours extends Modal {
	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const form = new Form(N_form);

			const settings = Settings.getInstance();

			const data = (settings.get('HOURS') || {}) as { [key: string]: any };

			data.numberLine = data.numberLine || 1;

			form.setData(data);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener2('click', () => {
				const data = form.getData();

				settings.setForUser(Utils.userID, 'HOURS', data);

				this.resolve(data);
			});
		});
	}
}

export default SettingsHours;

import { Controller } from '@autoprog/core-client';

import 'moment-ferie-fr';

import _ from 'lodash';
import moment from 'moment';

import M_holidays from '../modals/Holidays';
import M_settings from '../modals/SettingsHours';
import M_travel from '../modals/Travel';

import Utils from '@libs/utils/Utils';
import UtilsHours from '../libs/UtilsHours';

import P_holidays from '../libs/printer/Holidays';

import HoursDescriptionEditor from '../libs/aggrid/HoursDescriptionEditor';
import HoursEditor from '../libs/aggrid/HoursEditor';

import S_Holidays from '@services/Hours/HolidaysService';
import S_Hours from '@services/Hours/HoursService';
import S_Travels from '@services/Hours/TravelsService';
import S_TravelsDetails from '@services/Hours/TravelsDetailsService';

import { AllModules, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';

class Hours extends Controller {
	private el: HTMLElement;

	private month: string = '';
	private year: string = '';

	private gridOptionsTravel: GridOptions = {};
	private gridOptionsHours: GridOptions = {};
	private gridOptionsHolidays: GridOptions = {};

	private _descriptions: string[] = [];
	private _locations: string[] = [];
	private _projects: string[] = [];

	private travelsDetails: { [key: string]: any }[] = [];

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.initFilter();
		this.initHours();
		this.initTravel();
		this.initHoliday();

		this.getData();

		const N_settings = this.el.querySelector('#settings') as HTMLButtonElement;

		N_settings.addEventListener2('click', () => {
			new M_settings().open().then(() => {
				this.getData();
			});
		});
	}

	private initFilter() {
		const N_month = this.el.querySelector('#month') as HTMLSelectElement;
		const N_year = this.el.querySelector('#year') as HTMLSelectElement;

		const years = [
			moment().subtract(1, 'year').format('YYYY'),
			moment().format('YYYY'),
			moment().add(1, 'year').format('YYYY')
		];

		for (const item of years) {
			const N_options = new Option(item, item);
			N_year.append(N_options);
		}

		this.month = N_month.value = moment().format('MM');
		this.year = N_year.value = moment().format('YYYY');

		N_month.addEventListener2('change', () => {
			this.month = N_month.value;

			this.getData();
		});

		N_year.addEventListener2('change', () => {
			this.year = N_year.value;

			this.getData();
		});
	}

	private initHours() {
		this.gridOptionsHours = {
			columnDefs: [
				{
					headerName: 'Semaine',
					field: 'week',
					rowGroup: true,
					hide: true,
					editable: false,
					cellRenderer: (params) => {
						return `Semaine ${params.value}`;
					}
				}, {
					headerName: 'Date',
					field: 'date',
					rowGroup: true,
					hide: true,
					editable: false,
					cellRenderer: (params) => {
						return _.upperFirst(moment(params.value, 'DD/MM/YYYY').format('ddd DD/MM'));
					}
				}, {
					headerName: 'H. Debut',
					field: 'startHours',
					sort: 'asc',
					sortable: true,
					width: 100,
					suppressSizeToFit: true,
					cellEditor: HoursEditor,
					colSpan: (params) => {
						return params.node && (params.node.field === 'week' || params.node.field === 'date') ? 6 : 1;
					},
					comparator: (valueA, valueB, nodeA, nodeB) => {
						if (nodeA.data && nodeB.data) {
							const startHoursA = nodeA.data.startHours || '23:59';
							const startHoursB = nodeB.data.startHours || '23:59';

							const dateA = moment(`${nodeA.data.date} ${startHoursA}`, 'DD/MM/YYYY HH:mm');
							const dateB = moment(`${nodeB.data.date} ${startHoursB}`, 'DD/MM/YYYY HH:mm');

							if (dateA.isBefore(dateB)) {
								return -1;
							} else {
								return 1;
							}
						}

						return 0;
					},
					cellRenderer: (params) => {
						if (params.data) {
							return params.value || '<span class="text-muted">H. Debut</span>';
						} else {
							let data: any[] = [];

							if (params.node.field === 'week') {
								data = _.map(params.node.childrenAfterSort, 'childrenAfterSort');
								data = _.flatten(data);
							} else {
								data = params.node.childrenAfterSort;
							}

							data = _.map(data, 'data');

							let tripTime = 0;
							let workTime = 0;

							let numberDayWork = 0;

							const dates = _.uniq(_.map(data, 'date'));

							for (const item of data) {
								if (item.startHours && item.endHours && !item.ignore) {
									const startDate = moment(`${item.date} ${item.startHours}`, 'DD/MM/YYYY HH:mm');
									const endDate = moment(`${item.date} ${item.endHours}`, 'DD/MM/YYYY HH:mm');

									workTime += endDate.diff(startDate, 'minute');

									if (item.isTravel) {
										tripTime += UtilsHours.calculTimeTrip(item.date, startDate, endDate);
									}
								}
							}

							for (const date of dates) {
								if (date) {
									const dateMoment = moment(date, 'DD/MM/YYYY');

									//@ts-ignore
									if ([1, 2, 3, 4, 5].indexOf(dateMoment.day()) !== -1 && !(dateMoment.isFerie() && dateMoment.getFerie() !== 'Pentecôte')) {
										numberDayWork++;
									}
								}
							}

							workTime -= tripTime;

							if (params.node.field === 'week') {
								return `
                                    <span class="mr-5">Du ${data[0].date} au ${_.last(data).date}</span>
                                    <div class="d-inline-block" style="width:150px">
                                        <span class="font-weight-bold">Travail : </span>${UtilsHours.convertMinToString(workTime)} / ${numberDayWork * 7}h
                                    </div>
                                    <div class="d-inline-block" style="width:100px">
                                        <span class="ml-2 font-weight-bold"">Trajet : </span>${UtilsHours.convertMinToString(tripTime)}
                                    </div>
                                `;
							} else {
								const tmp = this.getTravelByDate(params.node.key);

								let error = false;

								//gestion error
								let index = 0;
								for (const item of data) {
									let startHours = item.startHours || '';
									let endHours = item.endHours || '';

									let startNextHours = (data[index + 1] || {}).startHours || '';
									let endPrevHours = (data[index - 1] || {}).endHours || '';

									startHours = startHours.replace(':', '');
									endHours = endHours.replace(':', '');

									endPrevHours = endPrevHours.replace(':', '');
									startNextHours = startNextHours.replace(':', '');

									if (parseInt(startHours) > parseInt(endHours)) {
										error = true;
										break;
									}

									if (endPrevHours && startHours) {
										if (parseInt(endPrevHours) > parseInt(startHours)) {
											error = true;
											break;
										}
									}

									if (startNextHours && endHours) {
										if (parseInt(endHours) > parseInt(startNextHours)) {
											error = true;
											break;
										}
									}

									index++;
								}

								const N_div = document.createElement('div');

								N_div.innerHTML = `
                                    <div class="d-inline-block" style="width:150px">
                                        <span class="font-weight-bold">Travail : </span>${UtilsHours.convertMinToString(workTime)}
                                    </div>
                                    <div class="d-inline-block" style="width:150px">
                                        <span class="ml-2 font-weight-bold">Trajet : </span>${UtilsHours.convertMinToString(tripTime)}
                                    </div>
                                    <div class="d-inline-flex"> 
                                        <div class="mr-3">
                                            <input type="number" class="mr-1" style="max-width: 50px" name="mealLittleTravel" value="${tmp.mealLittleTravel || 0}">
                                            Panier Repas
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="morning" ${tmp.morning ? 'checked' : ''}>
                                            Petit déj
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="midday" ${tmp.midday ? 'checked' : ''}>
                                            Repas midi
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="evening" ${tmp.evening ? 'checked' : ''}>
                                            Repas soir
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="night" ${tmp.night ? 'checked' : ''}>
                                            Nuit
                                        </div>
                                        <div class="mr-3">
                                            <input type="checkbox" name="nightIDF" ${tmp.nightIDF ? 'checked' : ''}>
                                            Nuit IDF
                                        </div>
                                        <div class="mr-3">
                                            Km : 
                                            <input style="max-width: 100px" type="number" name="km" value="${tmp.km || 0}"/>
                                        </div>

                                    </div>
                                    ${error ? '<i class="ml-5 text-danger icon icon-warning"></i>' : ''}
                                `;

								const N_inputs = N_div.querySelectorAll('input') as NodeListOf<HTMLInputElement>;

								N_inputs.forEach((N_input) => {
									const name = N_input.name;

									N_input.addEventListener2('change', async () => {
										const id = `${Utils.userID}_${moment(params.node.key, 'DD/MM/YYYY').format('YYYY_MM_DD')}`;

										const data: { [key: string]: any } = {
											date: params.node.key
										};

										data._id = id;
										data.user = Utils.userID;

										if (name === 'km' || name === 'mealLittleTravel') {
											data[name] = N_input.value;
										} else {
											data[name] = N_input.checked ? 1 : 0;
										}

										await S_TravelsDetails.getInstance().save(data);

										await this.getData(true);
									});
								});

								return N_div;
							}
						}
					},
					cellStyle: (params) => {
						if (params.data) {
							const data = params.node.parent.childrenAfterSort;

							if (params.node.childIndex !== 0) {
								let startHours = params.data.startHours;
								let endPrevHours = data[params.node.childIndex - 1].data.endHours;

								if (startHours && endPrevHours) {
									startHours = startHours.replace(':', '');
									endPrevHours = endPrevHours.replace(':', '');

									if (parseInt(startHours) < parseInt(endPrevHours)) {
										return {
											background: '#ef9a9a'
										};
									}
								}
							}

							return {
								background: ''
							};
						}
					}
				}, {
					headerName: 'H. Fin',
					field: 'endHours',
					width: 100,
					suppressSizeToFit: true,
					cellEditor: HoursEditor,
					cellRenderer: (params) => {
						if (params.data) {
							return params.value || '<span class="text-muted">H. Fin</span>';
						}

						return '';
					},
					cellStyle: (params) => {
						if (params.data) {
							const data = params.node.parent.childrenAfterSort;

							if (data[params.node.childIndex + 1]) {
								let startNextHours = data[params.node.childIndex + 1].data.startHours;
								let endHours = params.data.endHours;

								if (startNextHours && endHours) {
									startNextHours = startNextHours.replace(':', '');
									endHours = endHours.replace(':', '');

									if (parseInt(startNextHours) < parseInt(endHours)) {
										return {
											background: '#ef9a9a'
										};
									}
								}
							}

							return {
								background: ''
							};
						}
					}
				}, {
					headerName: 'Emplacement',
					field: 'location',
					width: 150,
					suppressSizeToFit: true,
					cellEditor: HoursDescriptionEditor,
					cellEditorParams: {
						list: this.getLocations.bind(this)
					},
					cellRenderer: (params) => {
						if (params.data) {
							return params.value || '<span class="text-muted">Emplacement</span>';
						}

						return '';
					}
				}, {
					headerName: 'Projet',
					field: 'project',
					width: 150,
					suppressSizeToFit: true,
					cellEditor: HoursDescriptionEditor,
					cellEditorParams: {
						list: this.getProjects.bind(this)
					},
					cellRenderer: (params) => {
						if (params.data) {
							return params.value || '<span class="text-muted">Projet</span>';
						}

						return '';
					}
				}, {
					headerName: 'Description',
					field: 'description',
					cellEditor: HoursDescriptionEditor,
					cellEditorParams: {
						list: this.getDescriptions.bind(this)
					},
					cellRenderer: (params) => {
						if (params.data) {
							return params.value || '<span class="text-muted">Description</span>';
						}

						return '';
					}
				}, {
					headerName: 'Details',
					width: 60,
					editable: false,
					suppressSizeToFit: true,
					cellClass: 'text-center',
					cellRenderer: (params) => {
						if (params.data.isTravel) {
							return '<i class="icon icon-solid-car"></i>';
						}

						if (params.data.isHolidays) {
							return '<i class="icon icon-solid-umbrella-beach"></i>';
						}

						return '';
					}
				}, {
					headerName: '#',
					field: '_id',
					width: 60,
					suppressSizeToFit: true,
					editable: false,
					cellRenderer: (params) => {
						if (params.node.field === 'week') {
							return '';
						}

						if (!params.data) {
							const date = params.node.key;

							const N_addLine = document.createElement('button');

							N_addLine.classList.add('btn', 'btn-success');
							N_addLine.setAttribute('style', '--size:24px; font-size:.8rem;');

							N_addLine.innerHTML = '<i class="icon icon-solid-plus"></i>';

							const data = _.map(params.node.childrenAfterGroup || [], 'data');

							N_addLine.disabled = data[0].lock;

							N_addLine.addEventListener2('click', async () => {
								const data = await S_Hours.getInstance().save({
									week: moment(date, 'DD/MM/YYYY').format('WW'),
									date
								});

								params.api?.updateRowData({
									add: [data.data.data]
								});

								params.node.setExpanded(true);
							});

							return N_addLine;
						} else {
							if (params.data.isTravel) {
								const N_travel = document.createElement('button');

								N_travel.classList.add('btn', 'btn-info');
								N_travel.setAttribute('style', '--size:24px; font-size:.8rem;');

								N_travel.innerHTML = '<i class="icon icon-solid-car"></i>';

								N_travel.addEventListener2('click', () => {
									new M_travel({
										id: params.data.idTravel
									}).open().then(async () => {
										this.getData();
									});
								});

								return N_travel;
							} else if (params.data.isHolidays) {
								return '';
							} else {
								const N_delete = document.createElement('button');

								N_delete.classList.add('btn', 'btn-danger');
								N_delete.setAttribute('style', '--size:24px; font-size:.8rem;');

								N_delete.innerHTML = '<i class="icon icon-trash-alt"></i>';

								N_delete.disabled = params.data.lock;

								N_delete.setAttribute('confirmation', '');

								N_delete.addEventListener2('click', async () => {
									if (params.value) {
										await S_Hours.getInstance().delete(params.value);
									}

									if (params.node.parent.childrenAfterGroup.length === 1) {
										const data = await S_Hours.getInstance().save({
											week: moment(params.data.date, 'DD/MM/YYYY').format('WW'),
											date: params.data.date
										});

										params.api?.updateRowData({
											add: [data.data.data],
											remove: [params.data]
										});
									} else {
										params.api?.updateRowData({
											remove: [params.data]
										});
									}

									params.api?.refreshCells({ force: true });
								});

								return N_delete;
							}
						}
					}
				}
			],
			defaultColDef: {
				suppressMenu: true,
				resizable: true,
				editable: (params) => {
					return !params.data.isTravel && !params.data.isHolidays;
				}
			},
			suppressDragLeaveHidesColumns: true,
			autoGroupColumnDef: {
				headerName: 'Date',
				field: 'date',
				width: 200,
				suppressSizeToFit: true,
				editable: false,
				cellRendererParams: {
					suppressCount: true
				}
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			getRowStyle: (params: any) => {
				const date = moment().format('DD/MM/YYYY');

				const currentDate = moment(params.node.key, 'DD/MM/YYYY');

				if (params.node.field === 'date') {
					if (params.node.key === date) {
						return {
							background: '#dddddd'
						};

						//@ts-ignore
					} else if (currentDate.isFerie() && currentDate.getFerie() !== 'Pentecôte') {
						return {
							background: '#e5b07e'
						};
					}
				}

				if (params.node.data) {
					let startHours = params.node.data.startHours;
					let endHours = params.node.data.endHours;

					startHours = startHours.replace(':', '');
					endHours = endHours.replace(':', '');

					if (parseInt(startHours) > parseInt(endHours)) {
						return {
							background: '#ef9a9a'
						};
					}

					if (params.node.data.ignore) {
						return {
							background: '#9a9def'
						};
					}
				}

				return {
					background: 'white'
				};
			},
			getContextMenuItems: (params) => {
				if (params.node.field === 'date') {
					const date = params.node.key;

					return [{
						name: `Ajouter une ligne (${date})`,
						action: async () => {
							const data = await S_Hours.getInstance().save({
								week: moment(date, 'DD/MM/YYYY').format('WW'),
								date
							});

							params.api?.updateRowData({
								add: [data.data.data]
							});

							params.api?.setSortModel([
								{ colId: 'startHours', sort: 'asc' }
							]);
						}
					}, {
						name: 'Créer un déplacement sur la journée',
						action: () => {
							let label = '';

							if (params.node.childrenAfterSort && params.node.childrenAfterSort[0] && params.node.childrenAfterSort[0].data.location) {
								label = params.node.childrenAfterSort[0].data.location;
							}

							new M_travel({
								data: {
									infos: {
										startDate: moment(date, 'DD/MM/YYYY').add(12, 'hours'),
										endDate: moment(date, 'DD/MM/YYYY').add(12, 'hours'),
										label
									}
								}
							}).open().then(() => {
								this.getData();
							});
						}
					}];
				} else if (params.node.data) {
					const travels: any[] = [];

					const result: MenuItemDef[] = [];

					const date = params.node.data.date;

					if (params.node.data.startHours && params.node.data.endHours && !params.node.data.isTravel && !params.node.data.isHolidays) {
						this.gridOptionsTravel.api && this.gridOptionsTravel.api.forEachNode((node) => {
							const startDate = moment(node.data.infos.startDate, 'x').format('DD/MM/YYYY');
							const endDate = moment(node.data.infos.endDate, 'x').format('DD/MM/YYYY');

							if (startDate === date && !node.data.infos.startHours1) {
								travels.push({
									id: node.data._id,
									type: 'start',
									text: node.data.infos.label
								});
							}

							if (endDate === date && !node.data.infos.endHours2) {
								travels.push({
									id: node.data._id,
									type: 'end',
									text: node.data.infos.label
								});
							}
						});

						if (travels.length) {
							const obj: MenuItemDef = {
								name: 'Associer a un déplacement',
								subMenu: [] as MenuItemDef[]
							};

							for (const item of travels) {
								(obj.subMenu as MenuItemDef[]).push({
									name: `Heure ${item.type === 'start' ? 'aller' : 'retour'} ${item.text}`,
									action: async () => {
										//update depl
										const travel: { [key: string]: any } = {
											_id: item.id
										};

										if (item.type === 'start') {
											travel.infos.startHours1 = params.node.data.startHours;
											travel.infos.startHours2 = params.node.data.endHours;
										} else {
											travel.infos.endHours1 = params.node.data.startHours;
											travel.infos.endHours2 = params.node.data.endHours;
										}

										await S_Travels.getInstance().save(travel);

										await S_Hours.getInstance().save({
											_id: params.node.data._id,
											isTravel: true,
											idTravel: item.id
										});

										this.getData();
									}
								});
							}

							result.push(obj);
						} else {
							//si aucun déplacements créer
							result.push({
								name: 'Aucun déplacement ce jour',
								disabled: true
							});
						}
					} else {
						//si aucun déplacements créer
						result.push({
							name: 'Impossible d\'associer a un déplacement',
							disabled: true
						});
					}

					return result;
				} else {
					return [];
				}
			},
			onRowDataChanged: () => {
				this.getHours();
			},
			onCellEditingStopped: async (params) => {
				const colId = params.column.getColId();

				await S_Hours.getInstance().save(params.data);

				if (['startHours', 'endHours'].indexOf(colId) !== -1) {
					params.api.setSortModel([
						{ colId: 'startHours', sort: 'asc' }
					]);

					params.api.refreshCells({ force: true });
				}

				this.getHours();

				this._descriptions.push(params.data.description);
				this._locations.push(params.data.location);
				this._projects.push(params.data.project);

				this._descriptions = _.uniq(_.compact(this._descriptions));
				this._locations = _.uniq(_.compact(this._locations));
				this._projects = _.uniq(_.compact(this._projects));
			}

		};

		const N_grid = this.el.querySelector('#hours') as HTMLElement;

		new Grid(N_grid, this.gridOptionsHours, { modules: AllModules });

		const N_sethours = this.el.querySelector('#setHours') as HTMLButtonElement;

		N_sethours.addEventListener2('click', async () => {
			const data: { [key: string]: any }[] = [];

			const date = moment().format('DD/MM/YYYY');
			const week = moment().format('WW');

			const currentHours = UtilsHours.round(moment(), moment.duration(5, 'minutes')).format('HH:mm');

			this.gridOptionsHours.api?.forEachNode((node) => {
				if (node.data) {
					data.push(node.data);
				}
			});

			const lastItem = _.findLast(data, { date }) as any;

			const add: { [key: string]: any }[] = [];
			const update: { [key: string]: any }[] = [];

			if (lastItem) {
				if (!lastItem.startHours) {
					lastItem.startHours = currentHours;
					update.push(lastItem);
				} else if (lastItem.startHours && !lastItem.endHours) {
					lastItem.endHours = currentHours;
					await S_Hours.getInstance().save(lastItem);
					update.push(lastItem);
				} else if (lastItem.endHours) {
					const data = await S_Hours.getInstance().save({
						week,
						date,
						startHours: currentHours
					});

					add.push(data.data.data);
				}
			} else {
				const data = await S_Hours.getInstance().save({
					week,
					date,
					startHours: currentHours
				});

				add.push(data.data.data);
			}

			this.gridOptionsHours.api?.applyTransaction({
				update,
				add
			});

			this.gridOptionsHours.api?.forEachNode((node) => {
				if (node.key === date || node.key === week) {
					node.setExpanded(true);
				} else {
					node.setExpanded(false);
				}
			});

			this.getHours();
		});
	}

	//pour envoyer les descriptions dans l'aggrid de maniere asynchrone
	private getDescriptions() {
		return this._descriptions;
	}

	private getLocations() {
		return this._locations;
	}

	private getProjects() {
		return this._projects;
	}

	private initHoliday() {
		this.gridOptionsHolidays = {
			columnDefs: [
				{
					headerName: 'Type',
					field: 'infos.type',
					cellRenderer: (params) => {
						return ({
							0: 'Congé',
							1: 'Récup\'',
							2: 'Absence',
							3: 'Arrêt Maladie'
						} as { [key: string]: any })[params.value];
					}
				}, {
					headerName: 'Etat',
					width: 100,
					field: 'infos.state',
					tooltipField: 'infos.comment',
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.data.infos.type === '3') {
							return 'Envoyé';
						}

						if (params.data.cancel) {
							return 'Annulé';
						}

						return ({
							0: 'En attente',
							1: 'Validé',
							2: 'Validé sous conditions',
							3: 'Refusé'
						} as { [key: string]: any })[params.value];
					}
				}, {
					headerName: 'A partir',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return moment(params.data.infos.startDate, 'x').format('DD/MM') + ' ' + params.data.infos.startHours;
					}
				}, {
					headerName: 'Reprise le',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return moment(params.data.infos.endDate, 'x').format('DD/MM') + ' ' + params.data.infos.endHours;
					}
				}, {
					headerName: '#',
					field: '_id',
					width: 80,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_container = document.createElement('div');
						N_container.classList.add('btn-group');

						if (params.data.infos.type !== '3') {
							if (!params.data.cancel && params.data.infos.state !== '3' && params.data.validate) {
								const N_cancel = document.createElement('button');

								N_cancel.title = 'Annuler';

								N_cancel.classList.add('btn', 'btn-xs', 'btn-danger');
								N_cancel.setAttribute('style', '--size:24px; font-size:.8rem;');

								N_cancel.innerHTML = '<i class="icon icon-cancel-circle"></i>';

								N_cancel.setAttribute('confirmation', '');

								N_cancel.addEventListener2('click', async () => {
									await S_Holidays.getInstance().save({ _id: params.value }, { type: 'cancel' });

									this.getData();
								});

								N_container.appendChild(N_cancel);
							}

							const N_print = document.createElement('button');

							N_print.classList.add('btn', 'btn-xs', 'btn-grey');
							N_print.setAttribute('style', '--size:24px; font-size:.8rem;');

							N_print.innerHTML = '<i class="icon icon-solid-print"></i>';

							N_print.addEventListener2('click', () => {
								new P_holidays(params.value).print();
							});

							N_container.appendChild(N_print);
						}

						return N_container;
					}
				}
			],
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			suppressContextMenu: true,
			rowData: [],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		};

		const N_grid = this.el.querySelector('#holidays') as HTMLElement;

		new Grid(N_grid, this.gridOptionsHolidays, { modules: AllModules });

		const N_addHolidays = this.el.querySelector('#addHolidays') as HTMLButtonElement;

		N_addHolidays.addEventListener2('click', () => {
			new M_holidays().open().then(async () => {
				this.getData();
			});
		});

		$('[data-toggle="tab"][href="#holidays-tabs" ]').on('shown.bs.tab', () => {
			this.gridOptionsHolidays.api?.sizeColumnsToFit();
		});
	}

	private initTravel() {
		this.gridOptionsTravel = {
			columnDefs: [
				{
					headerName: 'Site',
					field: 'infos.label'
				}, {
					headerName: 'Debut',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const date = moment(params.data.infos.startDate, 'x');

						if (date.isValid()) {
							return date.format('DD/MM') + ' ' + params.data.infos.startHours1;
						} else {
							return '<span class="text-muted">Aucune Date</span>';
						}
					}
				}, {
					headerName: 'Fin',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const date = moment(params.data.infos.endDate, 'x');

						if (date.isValid()) {
							return date.format('DD/MM') + ' ' + params.data.infos.endHours2;
						} else {
							return '<span class="text-muted">Aucune Date</span>';
						}
					}
				}, {
					headerName: '#',
					field: '_id',
					width: 85,
					suppressSizeToFit: true,
					cellClass: 'p-0',
					cellRenderer: (params) => {
						const N_container = document.createElement('div');
						N_container.classList.add('btn-group');

						const N_edit = document.createElement('button');

						N_edit.classList.add('btn', 'btn-xs', 'btn-info');
						N_edit.setAttribute('style', '--size:24px; font-size:.8rem;');

						N_edit.innerHTML = '<i class="icon icon-solid-edit"></i>';

						N_edit.addEventListener2('click', () => {
							new M_travel({
								id: params.value
							}).open().then(() => {
								this.getData();
							});
						});

						const N_delete = document.createElement('button');

						N_delete.classList.add('btn', 'btn-xs', 'btn-danger');
						N_delete.setAttribute('style', '--size:24px; font-size:.8rem;');

						N_delete.innerHTML = '<i class="icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');

						N_delete.addEventListener2('click', async () => {
							await S_Travels.getInstance().save({ _id: params.value }, { type: 'delete' });

							params.api?.updateRowData({
								remove: [params.node.data]
							});

							this.getData();
						});

						N_container.appendChild(N_edit);
						N_container.appendChild(N_delete);

						return N_container;
					}
				}
			],
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			rowData: [],
			suppressContextMenu: true,
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		};

		const N_grid = this.el.querySelector('#travels') as HTMLElement;

		new Grid(N_grid, this.gridOptionsTravel, { modules: AllModules });

		const N_addTravel = this.el.querySelector('#addTravel') as HTMLButtonElement;

		N_addTravel.addEventListener2('click', () => {
			new M_travel().open().then(() => {
				this.getData();
			});
		});

		$('[data-toggle="tab"][href="#travels-tabs" ]').on('shown.bs.tab', () => {
			this.gridOptionsTravel.api?.sizeColumnsToFit();
		});
	}

	private getTravelsDetail() {
		const travelsRes = {
			night: 0,
			nightIDF: 0,
			meal: 0,
			mealLittleTravel: 0,
			morning: 0,
			km: 0
		};

		let index = 0;
		for (const item of this.travelsDetails) {
			if (item._id.includes(`${this.year}_${this.month}`)) {
				travelsRes.night += (item.night || 0);
				travelsRes.nightIDF += (item.nightIDF || 0);
				travelsRes.mealLittleTravel += Number(item.mealLittleTravel || 0);

				travelsRes.meal += (item.evening || 0) + (item.midday || 0);
				travelsRes.km += Number(item.km || 0);

				if (!this.travelsDetails[index - 1] || (!this.travelsDetails[index - 1].night && !this.travelsDetails[index - 1].nightIDF)) {
					travelsRes.morning += (item.morning || 0);
				}
			}

			index++;
		}

		const N_night = this.el.querySelector('#night') as HTMLElement;
		const N_nightIDF = this.el.querySelector('#nightIDF') as HTMLElement;
		const N_meal = this.el.querySelector('#meal') as HTMLElement;
		const N_morning = this.el.querySelector('#morning') as HTMLElement;
		const N_mealLittleTravel = this.el.querySelector('#mealLittleTravel') as HTMLElement;
		const N_tripkm = this.el.querySelector('#tripkm') as HTMLElement;

		N_night.innerHTML = travelsRes.night.toString();
		N_nightIDF.innerHTML = travelsRes.nightIDF.toString();
		N_meal.innerHTML = travelsRes.meal.toString();
		N_morning.innerHTML = travelsRes.morning.toString();
		N_mealLittleTravel.innerHTML = travelsRes.mealLittleTravel.toString();
		N_tripkm.innerHTML = travelsRes.km + 'km';
	}

	private getTravels(travels: any[]) {
		const rowData: { [key: string]: any }[] = [];

		const travelsRes = {
			costs: 0
		};

		for (const item of travels) {
			const startDateTravel = moment(item.infos.startDate, 'x');
			const endDateTravel = moment(item.infos.endDate, 'x');

			if (startDateTravel.isValid() && startDateTravel.format('MM_YYYY') === `${this.month}_${this.year}`) {
				//if (startDateTravel.isValid() && endDateTravel.isValid()) {

				travelsRes.costs += parseFloat(item.infos.costs || 0);

				//}
			}

			if ((startDateTravel.isValid() && startDateTravel.format('MM_YYYY') === `${this.month}_${this.year}`) ||
				(endDateTravel.isValid() && endDateTravel.format('MM_YYYY') === `${this.month}_${this.year}`)) {
				rowData.push(item);
			}
		}

		this.gridOptionsTravel.api?.setRowData(rowData);

		const N_costs = this.el.querySelector('#costs') as HTMLElement;

		N_costs.innerHTML = travelsRes.costs.toString() + '€';
	}

	private async getData(noResetExpend: boolean = false) {
		const expandRows: { [key: string]: boolean } = {};

		const { data } = await S_Hours.getInstance().getDataToModal(`${this.year}_${this.month}`, {
			type: 'month'
		});

		this._descriptions = data.lists.descriptions;
		this._locations = data.lists.locations;
		this._projects = data.lists.projects;

		this.travelsDetails = data.travelsDetails;

		this.getTravels(data.travels);
		this.getTravelsDetail();

		if (noResetExpend) {
			this.gridOptionsHours.api?.forEachNode((node) => {
				expandRows[node.key!] = node.expanded;
			});
		}

		this.gridOptionsHolidays.api?.setRowData(data.holidays);
		this.gridOptionsHours.api?.setRowData(data.hours);

		if (!noResetExpend) {
			const week = moment().format('WW');
			const day = moment().format('DD/MM/YYYY');

			this.gridOptionsHours.api?.forEachNode((node) => {
				if (node.key === day || node.key === week) {
					node.setExpanded(true);
				} else {
					node.setExpanded(false);
				}
			});
		} else {
			this.gridOptionsHours.api?.forEachNode((node) => {
				node.setExpanded(expandRows[node.key!]);
			});
		}
	}

	private getHours() {
		const N_hours = this.el.querySelector('#hours-time') as HTMLElement;
		const N_hoursWeek = this.el.querySelector('#hours-week-time') as HTMLElement;
		const N_tripTime = this.el.querySelector('#trip-time') as HTMLElement;

		let sum = 0;
		let sumWeek = 0;
		let tripTime = 0;
		let tripTimeWeek = 0;

		const currentStartWeek = moment().startOf('week');
		const currentEndWeek = moment().endOf('week');

		const currentStartMonth = moment().startOf('month');
		const currentEndMonth = moment().endOf('month');

		this.gridOptionsHours.api?.forEachNode((node) => {
			if (node.data && node.data.startHours && node.data.endHours && !node.data.ignore) {
				const start = moment(`${node.data.date} ${node.data.startHours}`, 'DD/MM/YYYY HH:mm');
				const end = moment(`${node.data.date} ${node.data.endHours}`, 'DD/MM/YYYY HH:mm');

				const sumTmp = end.diff(start, 'minute');
				let tripTimeTmp = 0;

				if (node.data.isTravel) {
					tripTimeTmp = UtilsHours.calculTimeTrip(node.data.date, start, end);
				}

				if (currentStartMonth.isSameOrBefore(start) && end.isSameOrBefore(currentEndMonth)) {
					sum += sumTmp;
					tripTime += tripTimeTmp;
				}

				if (currentStartWeek.isSameOrBefore(start) && end.isSameOrBefore(currentEndWeek)) {
					sumWeek += sumTmp;
					tripTimeWeek += tripTimeTmp;
				}
			}
		});

		//retourne le dernier jour du mois selectionner
		const date = moment(`${this.month}/${this.year}`, 'MM/YYYY').startOf('month');
		const endDate = moment(`${this.month}/${this.year}`, 'MM/YYYY').endOf('month');

		let numberWorkDayMonth = 0;
		let numberWorkDayWeek = 0;

		while (date.isSameOrBefore(endDate)) {
			//@ts-ignore
			if ([1, 2, 3, 4, 5].indexOf(date.day()) !== -1 && !(date.isFerie() && date.getFerie() !== 'Pentecôte')) {
				numberWorkDayMonth++;
			}

			date.add(1, 'day');
		}

		while (currentStartWeek.isSameOrBefore(currentEndWeek)) {
			//@ts-ignore
			if ([1, 2, 3, 4, 5].indexOf(currentStartWeek.day()) !== -1 && !(currentStartWeek.isFerie() && currentStartWeek.getFerie() !== 'Pentecôte')) {
				numberWorkDayWeek++;
			}

			currentStartWeek.add(1, 'day');
		}

		sum -= tripTime;
		sumWeek -= tripTimeWeek;

		N_hours.innerHTML = `${UtilsHours.convertMinToString(sum)} / ${7 * numberWorkDayMonth}h`;
		N_hoursWeek.innerHTML = `${UtilsHours.convertMinToString(sumWeek)} / ${7 * numberWorkDayWeek}h`;
		N_tripTime.innerHTML = UtilsHours.convertMinToString(tripTime);

		return sum;
	}

	private getTravelByDate(dateStr: string) {
		return _.find(this.travelsDetails, { date: dateStr }) || {};
	}

	public destructor() {

	}
}

export default Hours;

import GenericService from './GenericService';

class ConfigThermoService extends GenericService {
	private static instance: ConfigThermoService | null = null;

	constructor() {
		super('config_thermo');
	}

	public static getInstance(): ConfigThermoService {
		if (!ConfigThermoService.instance) {
			ConfigThermoService.instance = new ConfigThermoService();
		}

		return ConfigThermoService.instance;
	}

	public getConfig(data: { [key: string]: any }) {
		return this.post_function('getConfig', data);
	}
}

export default ConfigThermoService;

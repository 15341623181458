import GenericService from '../GenericService';

class QrCodeCodeService extends GenericService {
	private static instance: QrCodeCodeService | null = null;

	constructor() {
		super('qrcode_codes');
	}

	public static getInstance(): QrCodeCodeService {
		if (!QrCodeCodeService.instance) {
			QrCodeCodeService.instance = new QrCodeCodeService();
		}

		return QrCodeCodeService.instance;
	}

	public getRouteGenerateQrCodeByData(urlRedirection: string = '', notes: string = '', codeEncloser: string = '') {
		return `${this.uriFunction}/generateQrCodeByData?urlRedirection=${urlRedirection}&notes=${notes}&codeEncloser=${codeEncloser}`;
	}
}

export default QrCodeCodeService;

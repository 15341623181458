import GenericService from '../GenericService';

class TravelsDetailsService extends GenericService {
	private static instance: TravelsDetailsService | null = null;

	constructor() {
		super('travels_details');
	}

	public static getInstance(): TravelsDetailsService {
		if (!TravelsDetailsService.instance) {
			TravelsDetailsService.instance = new TravelsDetailsService();
		}

		return TravelsDetailsService.instance;
	}
}

export default TravelsDetailsService;

import { Controller, Tabs } from '@autoprog/core-client';

import C_Hours from '../tabs/HoursAll.hours';
import C_Projects from '../tabs/HoursAll.projects';

import T_Hours from '../../tpl/tabs/HoursAll.hours.html';
import T_Projects from '../../tpl/tabs/HoursAll.projects.html';

import History from '@libs/History';

class HoursAll extends Controller {
	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		const tabs = new Tabs({
			navElement: el.querySelector('.tabs-container') as HTMLElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'hours';

		tabs.add({
			name: 'Horaire',
			id: 'hours',
			controller: C_Hours,
			default: defaultTab === 'hours',
			template: T_Hours
		});

		tabs.add({
			name: 'Projets',
			id: 'projects',
			controller: C_Projects,
			default: defaultTab === 'projects',
			template: T_Projects
		});

		let firstLoad = true;
		tabs.on('change', (item: any) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/hours/hoursAll/${item.id}`);
			}
		});
	}

	public destructor() {

	}
}

export default HoursAll;

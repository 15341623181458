import { Module, Modules, Router } from '@autoprog/core-client';

import C_AddEdit from './js/controller/ConfigThermo';
import C_Database from './js/controller/ConfigThermoDashboard';

import T_Database from '@tpl/database.html';

import T_AddEdit from './tpl/configThermo.html';

import Navigation from '@libs/Navigation';

import CE_ConfigProbe from './js/libs/customElement/ConfigProbe';

import './css/config_thermo.scss';

class ConfigThermoModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Outils',
			order: 200
		});
		navigation.addLink({
			order: 0,
			category: 'Outils',
			name: 'Config Thermo',
			type: 'module',
			module: 'config_thermo',
			permissions: ['CONFIG_THERMO'],
			icon: 'temp-cold/line'
		});

		CE_ConfigProbe.register();
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/config_thermo',
			controller: C_Database as any,
			tpl: T_Database,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/config_thermo/edit',
			controller: C_AddEdit as any,
			tpl: T_AddEdit,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Config Thermo', ConfigThermoModule);

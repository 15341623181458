
class ConfigProbe extends HTMLElement {
	public static readonly tagName: string = 'ap-config-probe';

	public async connectedCallback() {
		let prefix = this.getAttribute('prefix') || '';

		if (prefix) {
			prefix += '.';
		}

		this.innerHTML = `
			<div class="form-group">
				<label>Type de capteur</label>
				<select class="custom-select form-control" name="${prefix}sensorType">
					<option value="PT100">PT100</option>
					<option value="PT1000" selected>PT1000</option>
					<option value="CTN-1">CTN-1</option>
					<option value="CTN-2">CTN-2</option>
					<option value="CTN-3">CTN-3</option>
					<option value="CTN-4">CTN-4</option>
				</select>
			</div>
			<div class="form-group">
				<label>Nombre de capteurs</label>
				<input type="number" min="0" class="form-control" value="1" name="${prefix}sensorNumber" id="sensorNumber">
			</div>
			<div class="form-group">
				<label>Type de câblage</label>
				<select class="form-control" name="${prefix}wiringType" id="wiringType">
					<option value="1" selected>Point Commun</option>
					<option value="2">2 fils</option>
				</select>
			</div>
			<div class="form-group">
				<label>Ajouter au coffret</label>
				<select class="form-control" name="${prefix}box" id="box"></select>
			</div>
		`;

		const N_sensorNumber = this.querySelector('#sensorNumber') as HTMLInputElement;

		N_sensorNumber.addEventListener2('input', () => {
			this.updateWiringTypeSelect();
		});
	}

	private updateWiringTypeSelect() {
		const N_sensorNumber = this.querySelector('#sensorNumber') as HTMLInputElement;
		const N_wiringType = this.querySelector('#wiringType') as HTMLSelectElement;

		const firstOption = N_wiringType.querySelector('[value="1"]') as HTMLOptionElement;
		const secondOption = N_wiringType.querySelector('[value="2"]') as HTMLOptionElement;

		if (N_sensorNumber!.valueAsNumber > 21) {
			firstOption.disabled = true;
			secondOption.selected = true;
		} else {
			firstOption.disabled = false;
		}
	}

	public setBox(box: any[]) {
		const N_box = this.querySelector('#box') as HTMLSelectElement;

		let first = true;

		for (const item of box) {
			const N_options = new Option(item.name, item._id, first, first);
			N_box.appendChild(N_options);

			first = false;
		}
	}

	public postInit() {
		this.updateWiringTypeSelect();
	}

	public static register() {
		customElements.define(ConfigProbe.tagName, ConfigProbe);
	}
}

export default ConfigProbe;

import { Module, Modules, Router } from '@autoprog/core-client';

import Navigation from '@libs/Navigation';

import C_Badges from './js/controllers/Badges';

import T_database from '@tpl/database.html';

class BadgesModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
		/*Permissions.register('APPS/BADGES', 'Badges');

		const keys = ['APPS/BADGES'];

		for (const item of keys) {
			Permissions.register(`${item.toUpperCase()}.SHOW`, 'Voir la Page');
			Permissions.register(`${item.toUpperCase()}.ADD`, 'Créer');
			Permissions.register(`${item.toUpperCase()}.EDIT`, 'Modifier');
			Permissions.register(`${item.toUpperCase()}.DELETE`, 'Supprimer');
			Permissions.register(`${item.toUpperCase()}.DUPLICATE`, 'Dupliquer');
		}*/
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Paramétrage',
			order: 1000
		});
		navigation.addLink({
			order: 0,
			category: 'Paramétrage',
			name: 'Badges',
			type: 'module',
			module: 'apps/badges',
			permissions: ['BADGES'],
			icon: 'aspect-ratio/line'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/apps/badges',
			controller: C_Badges as any,
			tpl: T_database,
			requireValidUser: true
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Badges', BadgesModule);

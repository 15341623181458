import { Tab } from '@autoprog/core-client';

import _ from 'lodash';
import moment from 'moment';

import RecapMonthByUser from '../modals/RecapMonthByUser';

import S_Users from '@services/User/UserService';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

class HoursAllHours extends Tab {
	private month: string = '';
	private year: string = '';

	private el: HTMLElement;

	private gridOptions: GridOptions = {};

	constructor(el: HTMLElement) {
		super(el);

		this.el = el.parentNode as HTMLElement;

		const header = this.el.querySelector('[data-tabs="hours"]') as HTMLElement;
		header.classList.remove('d-none');
		header.classList.add('d-flex');

		this.initFilter();
		this.initGrid();

		this.getData();

		/*let N_print = this.el.querySelector('#print') as HTMLButtonElement;

		N_print.addEventListener2('click', () => {

			//N_print.loading(new P_HoursAll(this.month, this.year).print())

		});*/
	}

	private initGrid() {
		if (this.gridOptions.api) {
			this.gridOptions.api.destroy();
		}

		this.gridOptions = {
			rowData: [],
			columnDefs: [
				{
					headerName: 'ID',
					field: 'user._id',
					width: 70,
					suppressSizeToFit: true
				}, {
					headerName: 'Employé',
					field: 'user.name'
				}, {

					headerName: '#',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_detail = document.createElement('button');

						N_detail.classList.add('btn', 'btn-info');

						N_detail.setAttribute('tooltip', 'Detail mois');

						N_detail.innerHTML = '<i class="icon icon-solid-eye"></i>';

						N_detail.addEventListener2('click', () => {
							const users: any[] = [];

							params.api?.forEachNode((node: any) => {
								users.push(node.data.user);
							});

							new RecapMonthByUser(users, params.rowIndex, this.month, this.year).open();
						});

						N_div.appendChild(N_detail);

						return N_div;
					}

				}
			],
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			suppressContextMenu: true,
			onGridReady: (params) => {
				params.api?.sizeColumnsToFit();
			}

		};

		const N_grid = this.el.querySelector('#hours') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });
	}

	private initFilter() {
		const N_month = this.el.querySelector('#month') as HTMLSelectElement;
		const N_year = this.el.querySelector('#year') as HTMLSelectElement;

		const years = [
			moment().subtract(1, 'year').format('YYYY'),
			moment().format('YYYY'),
			moment().add(1, 'year').format('YYYY')
		];

		for (const item of years) {
			const N_options = new Option(item, item);
			N_year.append(N_options);
		}

		this.month = N_month.value = moment().format('MM');
		this.year = N_year.value = moment().format('YYYY');

		N_month.addEventListener2('change', () => {
			this.month = N_month.value;

			this.initGrid();
			this.getData();
		});

		N_year.addEventListener2('change', () => {
			this.year = N_year.value;

			this.initGrid();
			this.getData();
		});
	}

	private async getData() {
		const users = await S_Users.getInstance().getAll();

		let rows: { [key: string]: any }[] = [];

		for (const user of users) {
			if (window.location.href.includes('127.0.0.1') || (user._id !== '1' && !user.manager)) {
				rows.push({
					user: {
						_id: user._id,
						name: S_Users.getInstance().displayRefByData(user)
					}
				});
			}
		}

		rows = _.sortBy(rows, 'user.name');

		this.gridOptions.api?.setRowData(rows);
	}

	public destructor() {
		const header = this.el.querySelector('[data-tabs="hours"]') as HTMLElement;
		header.classList.add('d-none');
		header.classList.remove('d-flex');
	}
}

export default HoursAllHours;

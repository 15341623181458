import GenericService from '../GenericService';

class TravelsService extends GenericService {
	private static instance: TravelsService | null = null;

	constructor() {
		super('travels');
	}

	public static getInstance(): TravelsService {
		if (!TravelsService.instance) {
			TravelsService.instance = new TravelsService();
		}

		return TravelsService.instance;
	}

	public getLastByDayAndUser(user: string = '') {
		return this.get_function('getLastByDayAndUser', { user });
	}
}

export default TravelsService;

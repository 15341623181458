
import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';

type itemAddLine = (index: number) => { [key: string]: any };

class Aggrid {
	private constructor() { }

	public static contextMenuAddLines(params: GetContextMenuItemsParams, item: itemAddLine = () => { return {}; }, lock: boolean = false) {
		const addLine = {
			name: 'Ajouter ligne(s)',
			disabled: lock,
			subMenu: [{
				name: '1 ligne',
				action: () => {
					params.api?.applyTransaction({
						add: [item(0)]
					});

					let index = -1;

					params.api?.forEachNode(() => {
						index++;
					});

					params.api?.ensureIndexVisible(index);
				}
			}, {
				name: '5 lignes',
				action: () => {
					let index = 0;

					params.api?.forEachNode(() => {
						index++;
					});

					const add: any[] = [];
					for (let i = 0; i < 5; i++) {
						add.push(item(i));
					}

					params.api?.applyTransaction({
						add
					});

					params.api?.ensureIndexVisible(index);
				}
			}, {
				name: '10 lignes',
				action: () => {
					let index = 0;

					params.api?.forEachNode(() => {
						index++;
					});

					const add: any[] = [];
					for (let i = 0; i < 10; i++) {
						add.push(item(i));
					}

					params.api?.applyTransaction({
						add
					});

					params.api?.ensureIndexVisible(index);
				}
			}, {
				name: '20 lignes',
				action: () => {
					let index = 0;

					params.api?.forEachNode(() => {
						index++;
					});

					const add: any[] = [];
					for (let i = 0; i < 20; i++) {
						add.push(item(i));
					}

					params.api?.applyTransaction({
						add
					});

					params.api?.ensureIndexVisible(index);
				}
			}]
		};

		const insertLine = {
			name: 'Insérer une ligne',
			disabled: lock,
			action: () => {
				params.api?.applyTransaction({
					add: [item(0)],
					addIndex: (params.node.rowIndex || 0) + 1
				});
			}
		};

		const res: MenuItemDef[] = [addLine];

		if (params.node) {
			res.push(insertLine);
		}

		return res;
	}

	public static addLinesAndScroll(params: GetContextMenuItemsParams, add: { [key: string]: any }[] = []) {
		let index = 0;

		params.api?.forEachNode(() => {
			index++;
		});

		params.api?.applyTransaction({
			add
		});

		params.api?.ensureIndexVisible(index);
	}
}

export default Aggrid;

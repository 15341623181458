import { LoggedUser } from '@autoprog/core-client';

import C_Database from '@js/controllers/Database';

import Utils from '@libs/utils/Utils';

//import M_add from '../modals/Holidays'
import M_validate from '../modals/ValidateHolidays';

import P_print from '../libs/printer/Holidays';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

import S_Holidays from '@services/Hours/HolidaysService';

class Holidays extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Absences',
			database: 'holidays',
			permission: 'HOURS/HOLIDAYS'
		});
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		//refusé ou annulé
		if (params.data.infos.state === '3' || params.data.infos.state === '4') {
			return {
				'background-color': '#ef9a9a'
			};
		}

		//validé ou validé sus confition ou arret maladie
		if (params.data.infos.state === '1' || params.data.infos.state === '2' || params.data.infos.type === '3') {
			return {
				'background-color': '#83cc96'
			};
		}

		//en attente
		if (params.data.infos.state === '0') {
			return {
				'background-color': '#ffe082'
			};
		}

		return {
			'background-color': 'white'
		};
	}

	protected addOnButtonGrid(params: any) {
		const result: HTMLElement[] = [];

		const user = LoggedUser.getInstance();

		if (params.data.infos.type !== '3') {
			if ((user.get('manager') || Utils.userID === '1') && !params.data.validate) {
				const N_validate = document.createElement('button');

				N_validate.classList.add('h-100', 'py-0', 'btn-transparent');

				N_validate.setAttribute('tooltip', 'Editer');

				N_validate.innerHTML = '<i class="text-success h5 icon icon-solid-check"></i>';

				N_validate.addEventListener2('click', () => {
					new M_validate(params.value).open().then(() => {
						this.refreshData();
					});
				});

				result.push(N_validate);
			} else if (!params.data.cancel && params.data.infos.state !== '3' && params.data.validate) {
				const N_cancel = document.createElement('button');

				N_cancel.classList.add('h-100', 'py-0', 'btn-transparent');

				N_cancel.setAttribute('tooltip', 'Annuler');

				N_cancel.innerHTML = '<i class="text-danger h5 icon icon-cancel-circle"></i>';

				N_cancel.setAttribute('confirmation', '');

				N_cancel.addEventListener2('click', async () => {
					await S_Holidays.getInstance().save({ _id: params.value }, { type: 'cancel' });
					this.refreshData();
				});

				result.push(N_cancel);
			}

			const N_print = document.createElement('button');

			N_print.classList.add('h-100', 'py-0', 'btn-transparent');

			N_print.setAttribute('tooltip', 'Imprimer');

			N_print.innerHTML = '<i class="text-drak h5 icon icon-solid-print"></i>';

			N_print.addEventListener2('click', () => {
				new P_print(params.value).print();
			});

			result.push(N_print);
		}

		return result;
	}

	protected get configFilter(): ConfigFilter {
		return [[{
			title: 'Etat : ',
			column: 'infos.state',
			type: 'checkbox',
			filters: [
				{
					value: '0',
					text: 'En attente',
					color: 'orange-200'
				}, {
					value: '1',
					text: 'Validé',
					color: 'green-200'
				}, {
					value: '2',
					text: 'Validé sous conditions',
					color: 'green-200'
				}, {
					value: '3',
					text: 'Refusé',
					color: 'red-200'
				}, {
					value: '4',
					text: 'Annulé',
					color: 'red-200'
				}, {
					value: '5',
					text: 'Envoyé',
					color: 'purple-200'
				}
			]
		}]];
	}
}

export default Holidays;

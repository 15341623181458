import { global } from '@autoprog/core-client';

import Settings from '@libs/Settings';

import axios from 'axios';
import moment from 'moment';

import T_template from '../../../tpl/blocks/Holidays.ejs';

class Holidays {
	private id = '';

	constructor(id: string) {
		this.id = id;
	}

	public async print() {
		const { data } = await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}holidays/${this.id}`);

		if (data.infos.startDate) {
			data.infos.startDate = moment(data.infos.startDate, 'x').format('DD/MM/YYYY');
		}

		if (data.infos.endDate) {
			data.infos.endDate = moment(data.infos.endDate, 'x').format('DD/MM/YYYY');
		}

		data.infos.user = (await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}users/${data.infos.user}`)).data;

		data.validate = data.validate || {};
		data.cancel = data.cancel || {};
		data.request = data.request || {};

		if (data.request.date) {
			data.request.date = moment(data.request.date, 'x').format('DD/MM/YYYY HH:mm');
		}

		if (data.validate.date) {
			data.validate.date = moment(data.validate.date, 'x').format('DD/MM/YYYY HH:mm');
		}

		if (data.cancel.date) {
			data.cancel.date = moment(data.cancel.date, 'x').format('DD/MM/YYYY HH:mm');
		}

		if (data.cancel.user) {
			data.cancel.user = (await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}users/${data.cancel.user}`)).data;
		}

		const I_settings = Settings.getInstance();

		const N_container = document.createElement('div');

		N_container.classList.add('containerPrint');

		N_container.innerHTML += T_template({
			data,
			settings: I_settings.get('AUTOPROG')
		});

		const N_svgList = N_container.querySelectorAll('svg') as NodeListOf<SVGSVGElement>;

		for (const N_svg of N_svgList) {
			N_svg.classList.add('h-100', 'w-100');
			N_svg.removeAttribute('viewBox');
			N_svg.removeAttribute('width');
			N_svg.removeAttribute('height');
		}

		document.body.appendChild(N_container);

		setTimeout(() => {
			window.print();

			document.body.removeChild(N_container);
		}, 1000);
	}
}

export default Holidays;


import { Alert, Router, global } from '@autoprog/core-client';

import _ from 'lodash';
import moment from 'moment';

import C_CommandCustomer from '@modules/Commands/js/controllers/Command.Customer';
import C_Database from '@js/controllers/Database';
import C_Quote from '../controllers/AddQuotes';

import M_QuoteDefaultOption from '../modals/AddQuote/QuoteDefaultOption';
import M_SaveAs from '../modals/AddQuote/SaveAs';
import M_SetRelunchDate from '@libs/modals/SetRelunchDate';

import M_ChangeState from '../modals/AddQuote/ChangeState';

import Utils from '@libs/utils/Utils';

import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';

import Decimal from '@libs/utils/Decimal';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

import CE_FilesIcon from '@libs/customElement/FilesIcon';

class Quote extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Devis',
			type: 'database',
			database: 'quotes'
		});

		const N_add = document.querySelector('#add') as HTMLButtonElement;
		N_add.setAttribute('ap-hidden', 'mobile');

		N_add.addEventListener('click', () => {
			C_Quote.open(null);
		}, {
			signal: this.abortSignal
		});
	}

	protected header() {
		const N_div = document.createElement('div');

		N_div.classList.add('d-flex', 'flex-grow-1');

		N_div.innerHTML = `
			<button class="btn btn-settings ml-auto" id="default_value" permission="QUOTES.DEFAULT_SETTINGS"  tooltip="Paramètres par défaut">
				<i class="icon icon-cog"></i>
			</button>
        `;

		const N_btn = N_div.querySelector('#default_value') as HTMLButtonElement;
		N_btn.addEventListener('click', () => {
			new M_QuoteDefaultOption().open();
		});

		return N_div;
	}

	protected iconsColumn(params: any) {
		const result: HTMLElement[] = [];

		const N_open = document.createElement('ap-open-document') as HTMLElement;
		N_open.dataset.id = params.data._id;
		N_open.dataset.table = 'quotes';
		result.push(N_open);

		const N_icon = document.createElement('ap-files-icon') as CE_FilesIcon;
		N_icon.data = params.data;
		result.push(N_icon as HTMLElement);

		if (params.data.notFinish) {
			const not_finish = document.createElement('i');
			not_finish.classList.add('icon', 'h5', 'icon-solid-flag', 'mr-2');
			not_finish.setAttribute('tooltip', 'Devis à finir');

			result.push(not_finish);
		}

		return result;
	}

	protected async buttonEdit(params: any) {
		if (!global.IS_MOBILE) {
			const tmp = _.find(global.OPEN_QUOTES, { id: params.data._id }) as any;

			if (tmp) {
				Alert.confirm('Devis déja ouvert', `
                    Ce devis est ouvert par ${tmp.user} <br>
                    Depuis ${tmp.data.date}
                `).then(() => {
					C_Quote.open(params.data._id);
				}).catch(() => {

				});
			} else {
				C_Quote.open(params.data._id);
			}
		}
	}

	protected async buttonDuplicate(params: any) {
		new M_SaveAs(params.value).open().then(() => {
			this.refreshData();
		});
	}

	protected customMobile(item: { [key: string]: any }, N_el: HTMLElement) {
		const router = Router.getInstance();

		const N_view = N_el.querySelector('#view-quote') as HTMLButtonElement;

		N_view.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();

			router.navigate(`#module/quote/view?id=${item._id}`);
		});
	}

	protected getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		if (params.node) {
			const result: any[] = [{
				name: 'Définir prochaine relance',
				icon: '<i class="icon icon-solid-redo"></i>',
				disabled: params.node.data.infos?.relunch?.disabled || !params.node.data.needRelaunch,
				action: async () => {
					new M_SetRelunchDate('quotes', params.node.data._id).open().then(() => {
						this.refreshData();
					});
				}
			}, 'separator', {
				name: 'Changement état',
				action: () => {
					new M_ChangeState(params.node.data._id).open().then(() => {
						this.refreshData();
					}).catch(() => { });
				}
			}];

			const order = params.node.data.contextMenu?.order;

			if (order) {
				result.push({
					name: `Voir commande (N° ${order.infos.internalNumber})`,
					icon: '<i class="icon icon-solid-file-alt"></i>',
					action: () => {
						C_CommandCustomer.open(order._id);
					}
				});
			}

			return [
				...super.getContextMenu(params),
				'separator',
				...result
			];
		} else {
			return [];
		}
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		if (params.data) {
			//a relancer
			if (params.data.needRelaunch) {
				return {
					'background-color': 'var(--ap-blue-50)',
					color: 'var(--ap-blue-900)'
				};
			}

			//pret a etre envoyé
			if (params.data.infos.state === '5') {
				return {
					'background-color': 'var(--ap-cyan-50)',
					color: 'var(--ap-cyan-900)'
				};
			}

			//en attente commande
			if (params.data.infos.state === '4') {
				return {
					'background-color': 'var(--ap-orange-50)',
					color: 'var(--ap-orange-900)'
				};
			}

			//refusé
			if (params.data.infos.state === '7' || params.data.infos.state === '2') {
				return {
					'background-color': 'var(--ap-red-50)',
					color: 'var(--ap-red-900)'
				};
			}

			//accepté
			if (params.data.infos.state === '1') {
				return {
					'background-color': 'var(--ap-green-50)',
					color: 'var(--ap-green-900)'
				};
			}

			//envoyé
			if (params.data.infos.sendDate && moment(params.data.infos.sendDate, 'YYYY-MM-DD').isSameOrBefore(moment(), 'day')) {
				return {
					'background-color': 'var(--ap-purple-50)',
					color: 'var(--ap-purple-900)'
				};
			}

			//a finir
			if (params.data.notFinish) {
				return {
					'background-color': 'var(--ap-blue-grey-50)',
					color: 'var(--ap-blue-grey-900)'
				};
			}
		}

		return {
			'background-color': 'white'
		};
	}

	protected onDataUpdate(params: any) {
		let price = new Decimal(0);

		params.api.forEachNodeAfterFilter((node: any) => {
			if (node.data.globalPrice) {
				price = price.plus(Decimal.setDisplayNumber(node.data.globalPrice.value));
			}
		});

		this.setDataTitle([{
			price: price.setSuffixAndHumanizeNumber('€'),
			text: 'Global'
		}]);
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					title: 'états : ',
					type: 'title'
				}, {
					column: 'infos.state',
					type: 'checkbox',
					filters: [
						{
							value: '0',
							text: 'En cours',
							color: 'white'
						},
						{
							value: '5',
							text: 'Prêt à être envoyé',
							color: 'cyan-200'
						},
						{
							value: '6',
							text: 'À valider',
							color: 'grey-200'
						},

						{
							value: 'send',
							text: 'Envoyé',
							color: 'purple-200'
						},
						{
							value: '1',
							text: 'Accepté',
							color: 'green-200'
						},
						{
							value: '2',
							text: 'Refusé',
							color: 'red-200'
						},
						{
							value: '4',
							text: 'Attente commande',
							color: 'orange-200'
						},
						{
							value: '7',
							text: 'Révisé',
							color: 'red-200'
						}
					]
				}
			], [
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Devis personnel'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'notFinish',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'À finir',
							color: 'blue-grey-50'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'needRelaunch',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'À relancer',
							color: 'light-blue'
						}
					]
				}, {
					type: 'separator'
				}, {
					title: 'Notifications : ',
					type: 'title'
				}, {
					column: 'hasNotif.notFinish',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'À finir'
						}
					]
				}, {
					column: 'hasNotif.validateQuote',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'À Valider'
						}
					]
				}
			]
		];
	}
}

export default Quote;


import C_Database from '@js/controllers/Database';

class HistoryTab extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Interface',
			database: 'silobox_history',
			permission: 'INTERFACE'
		});
	}
}

export default HistoryTab;

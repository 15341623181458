
import { Tab } from '@autoprog/core-client';

import moment from 'moment';

import Utils from '@libs/utils/Utils';

import M_modal from '../modals/Travel';

import S_Travels from '@services/Hours/TravelsService';

class TravelsMobileTabs extends Tab {
	private el: HTMLElement;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.init();

		const N_add = this.el.querySelector('#add') as HTMLButtonElement;

		N_add.addEventListener2('click', () => {
			new M_modal().open().then(async () => {
				this.init();
			});
		});
	}

	private async init() {
		const data = await S_Travels.getInstance().getLastByDayAndUser(Utils.userID);

		const N_list = this.el.querySelector('#list-travels') as HTMLElement;

		N_list.innerHTML = '';

		for (const item of data) {
			const N_div = document.createElement('div');
			N_div.classList.add('card-mobile');

			if (item.infos.startDate) {
				item.infos.startDate = moment(item.infos.startDate, 'x').format('DD/MM/YYYY');
			}

			if (item.infos.endDate) {
				item.infos.endDate = moment(item.infos.endDate, 'x').format('DD/MM/YYYY');
			}

			N_div.innerHTML = `
                <div class="w-100 text-md">
                    <div>
                        ${item.infos.label || '&nbsp;'}
                    </div>
                    <div class="text-muted text-sm">
                        ${item.infos.startDate || '&nbsp;'} ${item.infos.startHours1} - ${item.infos.endDate || '&nbsp;'} ${item.infos.endHours2}
                    </div>
                </div>
            
                <div class="ml-auto btn-group">

                    <button class="btn btn-info" id="edit">
                        <i class="icon icon-solid-edit"></i>
                    </button>

                    <button class="btn btn-danger" id="delete" confirmation>
						<i class="icon icon-trash-alt"></i>
					</button>

                </div>
            `;

			const N_edit = N_div.querySelector('#edit') as HTMLButtonElement;
			const N_delete = N_div.querySelector('#delete') as HTMLButtonElement;

			N_edit.addEventListener2('click', () => {
				new M_modal({
					id: item._id
				}).open().then(async () => {
					this.init();
				});
			});

			N_delete.addEventListener2('click', async () => {
				await S_Travels.getInstance().save({ _id: item._id }, { type: 'delete' });

				this.init();
			});

			N_list.appendChild(N_div);
		}
	}
}

export default TravelsMobileTabs;

import { Tab, global } from '@autoprog/core-client';

import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import Utils from '../libs/UtilsHours';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

class HoursAllProjects extends Tab {
	private year: string = '';

	private el: HTMLElement;

	private gridOptions: GridOptions = {};

	private users: { [key: string]: any }[] = [];

	private columnDefs: { [key: string]: any }[] = [];

	constructor(el: HTMLElement) {
		super(el);

		this.el = el.parentNode as HTMLElement;

		const header = this.el.querySelector('[data-tabs="projects"]') as HTMLElement;
		header.classList.remove('d-none');
		header.classList.add('d-flex');

		this.initFilter();
		this.initHours();
		this.getData();
	}

	private initHours() {
		this.gridOptions = {
			columnDefs: this.columnDefs,
			defaultColDef: {
				suppressMenu: true,
				resizable: true,
				sortable: true
			},
			suppressDragLeaveHidesColumns: true,
			suppressContextMenu: true,
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		};

		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });
	}

	private initFilter() {
		const N_year = this.el.querySelector('#yearProject') as HTMLSelectElement;

		const years = [
			moment().subtract(2, 'year').format('YYYY'),
			moment().subtract(1, 'year').format('YYYY'),
			moment().format('YYYY')
		];

		for (const item of years) {
			const N_options = new Option(item, item);
			N_year.append(N_options);
		}

		this.year = N_year.value = moment().format('YYYY');

		N_year.addEventListener2('change', () => {
			this.year = N_year.value;

			this.getData();
		});
	}

	private async getData() {
		const { data } = await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}users/_design/all/_view/byID`, {
			params: {
				include_docs: true
			}
		});

		this.users = _.map(data.rows, 'doc');

		const projects: { [key: string]: any } = {};
		const users: { [key: string]: any } = {};

		for (const item of this.users) {
			if (item._id !== '1' && !item.manager) {
				const tmp = await this.getDataByUser(item._id);

				for (const id in tmp) {
					projects[id] = projects[id] || {};

					for (const user in tmp[id]) {
						users[user] = users[user] || true;

						projects[id][user] = projects[id][user] || 0;

						projects[id][user] += tmp[id][user];
					}
				}
			}
		}

		const rows: { [key: string]: any }[] = [];

		for (const id in projects) {
			rows.push({
				project: id,
				users: projects[id]
			});
		}

		this.columnDefs = [
			{
				headerName: 'Projet',
				field: 'project',
				cellRenderer: (params: any) => {
					return params.value || '<span class="text-muted">Aucune Description</span>';
				}
			},
			{
				headerName: 'Autoprog',
				field: 'users.all',
				cellRenderer: (params: any) => {
					return Utils.convertMinToString(params.value);
				}
			}
		];

		for (const user in users) {
			if (user !== 'all') {
				const { data } = await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}users/${user}`);

				this.columnDefs.push({
					headerName: `${data.firstname} ${data.lastname[0]}.`,
					field: `users.${user}`,
					cellRenderer: (params: any) => {
						return Utils.convertMinToString(params.value);
					}
				});
			}
		}

		if (this.gridOptions.api) {
			this.gridOptions.api.setRowData(rows);
			this.gridOptions.api.setColumnDefs(this.columnDefs);
			this.gridOptions.api.sizeColumnsToFit();
		}
	}

	private async getDataByUser(id: string) {
		let { data } = await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}hours/_design/all/_view/byDateAndUser`, {
			params: {
				startkey: `"${id}_${this.year}_01"`,
				endkey: `"${id}_${this.year}_99"`,
				include_docs: true
			}
		});

		data = _.map(data.rows, 'doc');

		const projects: { [key: string]: any } = {};

		for (const item of data) {
			if (item.startHours && item.endHours) {
				item.location = item.location || '';
				item.project = item.project || '';

				const startDate = moment(`${item.date} ${item.startHours}`, 'DD/MM/YYYY HH:mm');
				const endDate = moment(`${item.date} ${item.endHours}`, 'DD/MM/YYYY HH:mm');

				let workTime = endDate.diff(startDate, 'minute');

				let tripTime = 0;

				if (item.isTravel) {
					tripTime += Utils.calculTimeTrip(item.date, startDate, endDate);
				}

				workTime -= tripTime;

				if (!item.isTravel && !item.isHolidays) {
					const id = _.compact([_.upperFirst(item.location), _.upperFirst(item.project)]).join('/');

					projects[id] = projects[id] || { all: 0 };
					projects[id][item.user] = projects[id][item.user] || 0;

					projects[id].all += workTime;
					projects[id][item.user] += workTime;
				}
			}
		}

		return projects;
	}

	public destructor() {
		const header = this.el.querySelector('[data-tabs="projects"]') as HTMLElement;
		header.classList.add('d-none');
		header.classList.remove('d-flex');
	}
}

export default HoursAllProjects;

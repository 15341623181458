import { Form, toaster } from '@autoprog/core-client';

import Settings from '@libs/Settings';

import Modal from '@libs/Modal';

import moment, { Moment } from 'moment';

import T_modal from '../../tpl/modals/holidays.html';

import FilesTab from '@libs/customElement/FilesTab';

import S_Holidays from '@services/Hours/HolidaysService';

import SignaturePad from 'signature_pad';

class Holiday extends Modal {
	private type = '';
	private id = '';

	private form: Form | any = null;

	private signaturePad: SignaturePad | null = null;
	private N_FilesTab: FilesTab | null = null;

	constructor(id: string = '', type: string = 'create') {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.type = type;
		this.id = id;

		this.on('opened', async () => {
			this.initFiles();

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			this.form = new Form(N_form);

			const data = await this.getData();

			this.form.setData(data);

			this.N_FilesTab!.postInit();

			if (this.type === 'create') {
				const N_canvas = this.element.querySelector('#signature') as HTMLCanvasElement;

				this.signaturePad = new SignaturePad(N_canvas, {
					backgroundColor: 'rgb(255, 255, 255)'
				});

				const N_clearSignature = this.element.querySelector('#clear_signature') as HTMLButtonElement;

				N_clearSignature.addEventListener2('click', () => {
					this.signaturePad!.clear();
				});

				const N_importSignature = this.element.querySelector('#import_signature') as HTMLButtonElement;

				N_importSignature.addEventListener2('click', () => {
					const signature = Settings.getInstance().get('SIGNATURE') as any;

					if (signature) {
						const ratioX = N_canvas.width / signature.width;
						const ratioY = N_canvas.height / signature.height;

						for (const item of signature.data) {
							for (const point of item.points) {
								point.x *= ratioX;
								point.y *= ratioY;
							}
						}

						this.signaturePad!.fromData(signature.data);
					} else {
						toaster.error('Signature introuvable');
					}
				});
			} else {
				const N_containerSignature = this.element.querySelector('#container_signature') as HTMLElement;

				N_containerSignature.remove();
			}

			const N_type = this.element.querySelector('[name="infos.type"]') as HTMLSelectElement;

			this.updateByType();

			N_type.addEventListener2('change', () => {
				this.updateByType();
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener2('click', async () => {
				N_save.loading(this.save())?.then(() => {
					this.resolve();
				});
			});
		});
	}

	private async save() {
		const data = this.form.getData() as { [key: string]: any };

		if (data.infos.startDate) {
			data.infos.startDate = (data.infos.startDate as Moment).format('x');
		}

		if (data.infos.endDate) {
			data.infos.endDate = (data.infos.endDate as Moment).format('x');
		}

		if (!data.infos.startDate || !data.infos.endDate || data.infos.endDate < data.infos.startDate) {
			toaster.error('Date invalide');
			throw new Error('Date invalide');
		} else {
			data._id = this.id;

			data.attachments = this.N_FilesTab!.data;

			if (this.type === 'create') {
				const signature = this.signaturePad!.toDataURL('image/svg+xml');

				data.request.signature = atob(signature.split(',')[1]);
			}

			await S_Holidays.getInstance().save(data, { type: this.type });
		}
	}

	private async getData() {
		let data: { [key: string]: any } = {};

		if (this.id) {
			data = await S_Holidays.getInstance().getById(this.id);

			if (data.infos.startDate) {
				data.infos.startDate = moment(data.infos.startDate, 'x');
			}

			if (data.infos.endDate) {
				data.infos.endDate = moment(data.infos.endDate, 'x');
			}

			this.N_FilesTab!.data = data.attachments || {};
		} else {
			const settings = Settings.getInstance().get('AUTOPROG') as any;

			data = {
				request: {
					city: settings.address.city
				}
			};
		}

		return data;
	}

	private initFiles() {
		this.N_FilesTab = this.element.querySelector(FilesTab.tagName) as FilesTab;

		this.N_FilesTab.setParentElement(this.element);

		this.N_FilesTab.setCallback(() => {
			this.save();
		});
	}

	private updateByType() {
		const N_type = this.element.querySelector('[name="infos.type"]') as HTMLSelectElement;
		const N_description = this.element.querySelector('[name="infos.description"]') as HTMLElement;
		const N_typeAbs = this.element.querySelector('[name="infos.typeAbs"]') as HTMLElement;

		if (N_type.value !== '2') {
			(N_description.parentNode as HTMLElement).classList.add('d-none');
			(N_typeAbs.parentNode as HTMLElement).classList.add('d-none');
		} else {
			(N_description.parentNode as HTMLElement).classList.remove('d-none');
			(N_typeAbs.parentNode as HTMLElement).classList.remove('d-none');
		}
	}
}

export default Holiday;

import { global } from '@autoprog/core-client';

import { GridOptions } from '@ag-grid-enterprise/all-modules';

import Utils from '@libs/utils/Utils';

import axios from 'axios';

class AgGridStateSaver {
	private gridOptions: GridOptions;
	private name: string;

	private timeoutSaveDatabase: any = null;

	// eslint-disable-next-line unused-imports/no-unused-vars
	private cb = (value: any) => { };

	constructor(gridOptions: GridOptions, name: string) {
		this.gridOptions = gridOptions;
		this.name = name;

		this.gridOptions.api?.addEventListener('columnEverythingChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnVisible', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnPinned', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnResized', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnRowGroupChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnValueChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnMoved', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnGroupOpened', this.save.bind(this));
		this.gridOptions.api?.addEventListener('filterChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('sortChanged', this.save.bind(this));
	}

	private saveInDatabase(value: { [key: string]: any }) {
		if (this.timeoutSaveDatabase) {
			clearTimeout(this.timeoutSaveDatabase);
		}

		this.timeoutSaveDatabase = setTimeout(async () => {
			const idUser = Utils.userID;

			const data: { [key: string]: any } = {
				_id: `${this.name}_${idUser}`
			};

			try {
				const tmp = (await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}ag-state-saver/${data._id}`)).data;
				data._rev = tmp._rev;
				data.columns = tmp.columns || {};
				data.columnsByPC = tmp.columnsByPC || {};
			} catch (e) {
				data.columns = {};
				data.columnsByPC = {};
			}

			data.columnsByPC[global.ID_PC] = {};

			data.filters = value.filters || {};

			let order = 0;
			for (const item of value.columnState) {
				data.columns[item.colId] = {
					hide: item.hide,
					sort: item.sort,
					pinned: item.pinned,
					order
				};

				data.columnsByPC[global.ID_PC][item.colId] = {
					width: item.width
				};

				order++;
			}
			this.cb(data);

			await axios.put(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}ag-state-saver/${data._id}`, data);
		}, 1000);
	}

	private save(): void {
		const columnState = this.gridOptions.columnApi?.getColumnState();

		const value: { [key: string]: any } = {
			columnState,
			filters: this.gridOptions.api?.getFilterModel()
		};

		this.saveInDatabase(value);
	}

	public setData(data: any) {
		let columnStateWithOrder: { [key: string]: any }[] = [];
		let columnStateWithoutOrder: { [key: string]: any }[] = [];

		const tmp: { [key: string]: any } = {};

		for (const colId in data.columns) {
			const columnDef = this.gridOptions.api?.getColumnDef(colId);

			tmp[colId] = tmp[colId] || {};

			if (!columnDef?.suppressMovable) {
				tmp[colId].order = data.columns[colId].order;
				tmp[colId].pinned = data.columns[colId].pinned;
			}

			tmp[colId].hide = data.columns[colId].hide;
			tmp[colId].sort = data.columns[colId].sort;
		}

		data.columnsByPC = data.columnsByPC || {};
		data.columnsByPC[global.ID_PC] = data.columnsByPC[global.ID_PC] || {};

		for (const colId in data.columnsByPC[global.ID_PC]) {
			const columnDef = this.gridOptions.api?.getColumnDef(colId);

			tmp[colId] = tmp[colId] || {};
			if (columnDef?.resizable) {
				tmp[colId].width = data.columnsByPC[global.ID_PC][colId].width;
			}
		}

		for (const colId in tmp) {
			if (colId === '_id_1') {
				tmp[colId].pinned = 'right';
			}

			if (colId === '_icons_') {
				tmp[colId].pinned = 'left';
			}

			if (tmp[colId].order !== undefined) {
				columnStateWithOrder[tmp[colId].order] = {
					colId,
					...tmp[colId]
				};
			} else {
				columnStateWithoutOrder.push({
					colId,
					...tmp[colId]
				});
			}
		}

		columnStateWithOrder = columnStateWithOrder.filter(item => !!item);
		columnStateWithoutOrder = columnStateWithoutOrder.filter(item => !!item);

		this.setValue({ columnState: [...columnStateWithOrder, ...columnStateWithoutOrder], filters: data.filters });
	}

	private setValue(data: { [key: string]: any } = {}) {
		this.gridOptions.columnApi?.applyColumnState({ state: data.columnState, applyOrder: true });

		this.gridOptions.api?.setFilterModel(data.filters);
	}

	public onUpdate(cb: (value: any) => void) {
		this.cb = cb;
	}

	public static async getValue(name: string) {
		const idUser = Utils.userID;

		try {
			const data = (await axios.get(`${global.COUCHDB_URL}/${global.COUCHDB_PREFIX}ag-state-saver/${name}_${idUser}`)).data;
			return data;
		} catch (e) {
			return {
				columns: {},
				columnsByPC: {}
			};
		}
	}
}

export default AgGridStateSaver;


import { LoggedUser, Router, SocketIO, global } from '@autoprog/core-client';

import moment from 'moment';

import Title from '@libs/Title';
import Utils from '@libs/utils/Utils';

import S_Notifications from '@services/NotificationService';

export type category = {
	icon: string,
	textFilter?: string,
	isFilter?: boolean,
	action?: (item: { [key: string]: any }, N_div: HTMLElement) => Promise<string | void>
};

export type filters = {
	text: string,
	keys: string[]
};

class Notifications {
	private static categories: Map<string, category> = new Map();
	private static filters: Map<string, filters> = new Map();

	private numberNotif = 0;
	private loggedUser: LoggedUser;
	private io: SocketIO;

	private currentFilter = ['all'];

	private static element: HTMLElement = document.createElement('div');
	private iconElement: HTMLElement;

	private static waitingDelete: { [key: string]: boolean } = {};

	constructor() {
		this.loggedUser = LoggedUser.getInstance();

		this.iconElement = document.createElement('button');

		this.io = SocketIO.getInstance();

		this.initRender();
		this.initEvent();
	}

	/**STATIC METHOD */

	public static addCategory(types: string[], item: category) {
		for (const type of types) {
			Notifications.categories.set(type, item);
		}
		if (item.isFilter) {
			Notifications.filters.set(types[0], {
				text: item.textFilter || types[0],
				keys: types
			});
		}
	}

	public static getIconByType(type: string) {
		const category = Notifications.categories.get(type);
		return category?.icon || '';
	}

	public static hasAction(item: { [key: string]: any }) {
		return Notifications.categories.has(item.type);
	}

	public static async getAction(item: { [key: string]: any }, N_div: HTMLElement) {
		const category = Notifications.categories.get(item.type);
		await (category && category.action && category.action(item, N_div));
	}

	public static async closeNotification(id: string) {
		Notifications.waitingDelete[id] = true;

		const N_el = Notifications.element.querySelector(`#container [data-id="${id}"] #spinner`) as HTMLElement;
		N_el.classList.add('d-flex');
		N_el.classList.remove('d-none');

		await S_Notifications.getInstance().save({
			_id: id,
			state: '1'
		});
	}

	/**END STATIC METHOD */

	/**INIT METHOD */

	private initRender() {
		this.renderIconButtonElement();
		this.renderColElement();

		this.renderFilters();

		if (global.IS_MOBILE) {
			const N_navbar = document.querySelector('.navbar-bottom #navbar-right-content') as HTMLElement;
			N_navbar.appendChild(this.iconElement);
		} else {
			const N_navbar = document.querySelector('#navbar-right-content') as HTMLElement;
			N_navbar.appendChild(this.iconElement);
		}
	}

	private initEvent() {
		this.io.on('notification.init', (data: { [key: string]: any }) => {
			if (data.user === Utils.userID) {
				this.updateNotif(data.data);
			}
		});

		this.io.on('notification.update', (data: { [key: string]: any }) => {
			if (data.user === Utils.userID) {
				this.updateNotif(data.data);
			}
		});

		this.io.on('notification.delete', (data: { [key: string]: any }) => {
			if (data.user === Utils.userID) {
				const N_container = Notifications.element.querySelector('#container') as HTMLElement;
				for (const item of data.data) {
					const N_div = N_container.querySelector(`[data-id="${item}"]`) as HTMLElement;
					N_div && N_div.remove();
					this.numberNotif--;
					delete Notifications.waitingDelete[item];
				}
			}

			this.updateNumberFilter({});
			this.updateNumberNotif(this.numberNotif);
		});

		this.loggedUser.on('login', () => {
			this.io.emit('notification.init', { user: Utils.userID });
		});

		this.loggedUser.on('logout', () => {
			const N_divs = Notifications.element.querySelectorAll('#container [data-id]') as NodeListOf<HTMLElement>;
			for (const N_div of N_divs) {
				N_div && N_div.remove();
			}
			this.numberNotif = 0;
			this.updateNumberFilter({});
			this.updateNumberNotif(this.numberNotif);
		});

		if (this.loggedUser.isLogged) {
			this.io.whenConnected(() => {
				this.io.emit('notification.init', { user: Utils.userID });
			});
		}
	}

	/**END INIT METHOD */

	/**METHOD RENDER */

	private renderIconButtonElement() {
		this.iconElement.style.whiteSpace = 'nowrap';
		this.iconElement.classList.add('circle-btn', 'dropdown', 'position-relative');

		if (global.IS_MOBILE) {
			this.iconElement.classList.add('mr-3');
		}

		this.iconElement.innerHTML = `
			<span class="badge custom-badge d-none" id="number-notif">0</span>
			<i class="icon icon-solid-bell" id="icon-notif"></i>
        `;

		this.iconElement.addEventListener('click', () => {
			this.open();
		});
	}

	private renderColElement() {
		Notifications.element.id = 'notif-container';

		Notifications.element.innerHTML = `
            <div class="h4 d-flex font-weight-bold">
                Notifications
                <i class="icon icon-solid-times cursor-pointer ml-auto" id="close-notif"></i>
            </div>
            <div id="filter">
				<div class="active" data-filter="all">Tous</div>
			</div>
			<div class="text-muted w-100 text-center mt-4" id="noNotification">Aucune notification</div>
            <div class="flex-grow-1 scroll-y mt-2 d-flex flex-column" id="container"></div>
            <div class="text-center d-flex w-100">
                <button class="btn btn-info w-50 mr-3" ap-hidden="mobile" id="history">
                    Historique
                </button>
                <button class="btn btn-danger w-50" confirmation id="delete-all">
                    Supprimer tous
                </button>
            </div>
        `;

		const N_viewAll = Notifications.element.querySelector('[data-filter="all"]') as HTMLButtonElement;
		N_viewAll.addEventListener('click', () => {
			this.resetFilter();

			this.currentFilter = ['all'];

			N_viewAll.classList.add('active');

			this.updateFilter();
		});

		const N_historyNotif = Notifications.element.querySelector('#history') as HTMLButtonElement;

		N_historyNotif.addEventListener('click', () => {
			this.close();
			Router.getInstance().navigate('/module/apps/notification');
		});

		const N_deleteAll = Notifications.element.querySelector('#delete-all') as HTMLButtonElement;

		N_deleteAll.addEventListener('click', () => {
			const N_divs = Notifications.element.querySelectorAll('[data-type]') as NodeListOf<HTMLElement>;

			N_divs.forEach((N_div) => {
				const type = N_div.dataset.type || '';

				if (this.currentFilter.includes(type) || this.currentFilter[0] === 'all') {
					const id = N_div.dataset.id || '';

					Notifications.closeNotification(id);
				}
			});
		});

		const N_closeNotif = Notifications.element.querySelector('#close-notif') as HTMLElement;

		N_closeNotif.addEventListener('click', () => {
			this.close();
		});

		(document.querySelector('#floating-modals-container') as HTMLElement).appendChild(Notifications.element);
	}

	private renderFilters() {
		const N_container = Notifications.element.querySelector('#filter') as HTMLElement;

		for (const [id, { text, keys }] of Notifications.filters.entries()) {
			const N_div = document.createElement('div');
			N_div.classList.add('position-relative');
			N_div.setAttribute('tooltip', text);
			N_div.setAttribute('data-filter', keys.join(','));

			N_div.innerHTML = `
				<i class="icon ${Notifications.getIconByType(id)}"></i>
				<span class="badge" data-number-filter>0</span>
			`;

			N_div.addEventListener('click', () => {
				const N_filters = Notifications.element.querySelectorAll('[data-filter]') as NodeListOf<HTMLInputElement>;

				this.currentFilter = keys;

				N_filters.forEach((N_el) => {
					N_el.classList.remove('active');
				});

				N_div.classList.add('active');

				this.updateFilter();
			});

			N_container.appendChild(N_div);
		}
	}

	private updateRenderItem(N_div: HTMLElement, item: { [key: string]: any }) {
		const icon = Notifications.getIconByType(item.type);
		const dateStr = this.convertDatetoText(item.date);

		N_div.dataset.date = item.date;

		const N_icon = N_div.querySelector('[data-key="icon"]') as HTMLElement;
		const N_title = N_div.querySelector('[data-key="title"]') as HTMLElement;
		const N_subTitle = N_div.querySelector('[data-key="subTitle"]') as HTMLElement;
		const N_description = N_div.querySelector('[data-key="description"]') as HTMLElement;
		const N_createBy = N_div.querySelector('[data-key="createBy"]') as HTMLElement;
		const N_spinner = N_div.querySelector('#spinner') as HTMLElement;

		N_icon.className = `icon ${icon}`;
		N_title.innerHTML = item.title;
		N_subTitle.innerHTML = item.subTitle;
		N_createBy.innerHTML = `Par ${item.createBy}<span class="mx-2">&bull;</span>${dateStr}`;
		N_description.innerHTML = item.description;

		if (item.description) {
			N_description.classList.remove('d-none');
		} else {
			N_description.classList.add('d-none');
		}

		if (Notifications.waitingDelete[item._id]) {
			N_spinner.classList.add('d-flex');
			N_spinner.classList.remove('d-none');
		} else {
			N_spinner.classList.remove('d-flex');
			N_spinner.classList.add('d-none');
		}
	}

	private renderItem(item: { [key: string]: any } = {}) {
		const N_div = document.createElement('div');
		N_div.classList.add('notif-item');

		N_div.dataset.type = item.type;
		N_div.dataset.id = item._id;

		N_div.innerHTML = `
			<div class="mr-2">
				<i class="icon" style="line-height:20px" data-key="icon"></i>
			</div>
			<div>
				<div>
					<div class="mb-1" data-key="title" id="title"></div>
					<div data-key="subTitle" id="subTitle"></div>
				</div>
				<div class="my-2" data-key="description" id="description"></div>
				<div data-key="createBy" id="createBy"></div>
			<div>
			<i class="position-absolute icon icon-solid-times" id="close"></i>

			<div class="d-none position-absolute bg-grey-200 w-100 h-100 justify-content-center align-items-center" style="top:0; left:0; opacity:0.7" id="spinner">
				<i class="icon icon-solid-spinner icon-spin h2"></i>
			</div>
		`;

		this.updateRenderItem(N_div, item);

		const N_close = N_div.querySelector('#close') as HTMLElement;

		//TODO: a rendre générique
		if (['holiday', 'quote-validate', 'deliveries'].includes(item.type) && item.data && item.data.id) {
			N_close.classList.add('d-none');
		}

		N_close.addEventListener('click', async (e) => {
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();

			await Notifications.closeNotification(item._id);
		});

		N_div.addEventListener('click', async (e) => {
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();

			Notifications.getAction(item, N_div);
		});

		return N_div;
	}

	/**END METHOD RENDER */

	/**METHOD UPDATE */

	private updateNumberNotif(newLength: number) {
		const N_numberNotif = this.iconElement.querySelector('#number-notif') as HTMLElement;
		const N_iconNotif = this.iconElement.querySelector('#icon-notif') as HTMLElement;

		const N_noNotification = document.querySelector('#noNotification') as HTMLElement;

		this.numberNotif = this.numberNotif || 0;

		if (newLength) {
			N_numberNotif.classList.remove('d-none');
		} else {
			N_numberNotif.classList.add('d-none');
		}

		if (newLength > this.numberNotif) {
			N_iconNotif.classList.add('animate__animated', 'animate__infinite', 'animate__swing');
		}

		if (newLength === 0) {
			N_iconNotif.classList.remove('animate__animated', 'animate__infinite', 'animate__swing');
		}

		N_numberNotif.innerHTML = newLength.toString();

		this.numberNotif = newLength;

		if (this.numberNotif === 0) {
			N_noNotification.classList.remove('d-none');
		} else {
			N_noNotification.classList.add('d-none');
		}

		Title.getInstance().updateNotif(this.numberNotif);
	}

	private updateNumberFilter(data: { [key: string]: number }) {
		const N_div = Notifications.element.querySelectorAll('[data-filter]') as NodeListOf<HTMLElement>;

		N_div.forEach((N_el) => {
			const keys = (N_el.dataset.filter || '').split(',');

			const N_number = N_el.querySelector('[data-number-filter]') as HTMLElement;

			let value = 0;

			for (const key of keys) {
				value += (data[key] || 0);
			}

			if (N_number) {
				N_number.innerHTML = value.toString();

				if (value) {
					N_number.classList.remove('d-none');
				} else {
					N_number.classList.add('d-none');
				}
			}
		});
	}

	private async updateNotif(data: { [key: string]: any }[] = []) {
		const dataNumber: { [key: string]: number } = {};

		const N_container = document.querySelector('#notif-container #container') as HTMLElement;

		this.updateNumberNotif(data.length);

		if (data.length) {
			for (const item of data) {
				dataNumber[item.type] = dataNumber[item.type] || 0;
				dataNumber[item.type]++;

				const oldDiv = N_container.querySelector(`[data-id="${item._id}"]`) as HTMLElement;

				if (!oldDiv) {
					const N_div = this.renderItem(item);
					N_container.appendChild(N_div);
				} else {
					this.updateRenderItem(oldDiv, item);
				}
			}
		}

		this.updateFilter();

		this.updateNumberFilter(dataNumber);

		this.updateOrder();
	}

	private updateOrder() {
		const res: { date: number, N_div: HTMLElement }[] = [];

		const N_notifs = Notifications.element.querySelectorAll('.notif-item') as NodeListOf<HTMLElement>;

		for (const N_el of N_notifs) {
			res.push({
				date: Number(N_el.dataset.date || ''),
				N_div: N_el
			});
		}

		res.sort((a, b) => b.date - a.date);

		for (let i = 0; i < res.length; i++) {
			res[i].N_div.style.order = i.toString();
		}
	}

	private updateFilter() {
		const N_notifs = document.querySelectorAll('#notif-container [data-type]') as NodeListOf<HTMLElement>;

		if (this.currentFilter[0] === 'all') {
			N_notifs.forEach((N_el) => {
				N_el.classList.remove('d-none');
			});
		} else {
			N_notifs.forEach((N_el) => {
				const type = N_el.dataset.type || '';

				if (this.currentFilter.includes(type)) {
					N_el.classList.remove('d-none');
				} else {
					N_el.classList.add('d-none');
				}
			});
		}
	}

	/**END METHOD UPDATE */

	private open() {
		const N_iconNotif = this.iconElement.querySelector('#icon-notif') as HTMLElement;

		this.resetFilter();

		this.currentFilter = ['all'];

		(Notifications.element.querySelector('[data-filter="all"]') as HTMLElement).classList.add('active');

		N_iconNotif.classList.remove('animate__animated', 'animate__infinite', 'animate__swing');

		Notifications.element.classList.toggle('active');

		this.updateFilter();
	}

	private close() {
		Notifications.element.classList.toggle('active');
	}

	private resetFilter() {
		const N_filters = Notifications.element.querySelectorAll('[data-filter]') as NodeListOf<HTMLInputElement>;
		N_filters.forEach((N_el) => {
			N_el.classList.remove('active');
		});
	}

	private convertDatetoText(value: string) {
		const now = moment();

		const date = moment(value, 'x');

		let dateStr = '';

		if (now.isSame(date, 'day')) {
			let number = now.diff(date, 'seconds');

			const hours = Math.floor(number / 3600);
			number = number % 3600;
			const minute = Math.floor(number / 60);
			//let second = number % 60;

			if (hours || minute) {
				dateStr = `Il y a ${hours ? hours + ' h ' : ''} ${minute ? minute + ' min' : ''}`;
			} else {
				dateStr = 'Maintenant';
			}
		} else {
			dateStr = 'Le ' + date.format('DD MMMM YYYY');
		}

		return dateStr;
	}
}

export default Notifications;

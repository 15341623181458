import { Alert, Form, Tab } from '@autoprog/core-client';

import _ from 'lodash';

import Utils from '@libs/utils/Utils';

import moment, { Moment } from 'moment';

import M_modal from '../modals/HoursMobileEdit';
import M_travel from '../modals/Travel';

import UtilsHours from '../libs/UtilsHours';

import S_Hours from '@services/Hours/HoursService';
import S_TravelsDetails from '@services/Hours/TravelsDetailsService';

class HoursMobileTabs extends Tab {
	private el: HTMLElement;

	private currentDate = moment();

	private formDetailsTravels: Form;
	private formDate: Form;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		const N_title = this.el.parentElement?.querySelector('#page-title #addon') as HTMLElement;

		N_title.innerHTML = `
			<form id="form_hours">
				<div class="input-group">
					<div class="input-group-prepend">
						<button class="btn btn-grey-300" type="button" style="border-radius: 10px 0 0 10px" id="prev_day">
							<i class="icon icon-solid-chevron-left"></i>
						</button>
					</div>
					<input type="date" class="form-control" name="date" id="date">
					<div class="input-group-append">
						<button class="btn btn-grey-300 rounded-0" type="button" id="clone_day">
							<i class="icon icon-clone"></i>
						</button>
						<button class="btn btn-grey-300" type="button" style="border-radius: 0 10px 10px 0" id="next_day">
							<i class="icon icon-solid-chevron-right"></i>
						</button>
					</div>
				</div>
			</form>
		`;

		const N_date = N_title.querySelector('#date') as HTMLInputElement;
		const N_add = this.el.querySelector('#add') as HTMLInputElement;
		const N_save = this.el.querySelector('#save') as HTMLButtonElement;
		const N_prev_day = N_title.querySelector('#prev_day') as HTMLButtonElement;
		const N_next_day = N_title.querySelector('#next_day') as HTMLButtonElement;
		const N_clone_day = N_title.querySelector('#clone_day') as HTMLButtonElement;

		this.formDetailsTravels = new Form(this.el.querySelector('#form_travel') as HTMLFormElement);
		this.formDate = new Form(N_title.querySelector('#form_hours') as HTMLFormElement);

		this.formDate.setData({
			date: this.currentDate
		});

		this.initDay();

		N_date.addEventListener2('change', () => {
			this.currentDate = this.formDate.getDataByName('date') as Moment;

			this.initDay();
		});

		N_add.addEventListener2('click', async () => {
			await new M_modal(this.currentDate).open();
			this.initDay();
		});

		N_save.addEventListener2('click', () => {
			N_save.disabled = true;

			N_save.loading(new Promise<any>(async (resolve) => {
				const id = `${Utils.userID}_${this.currentDate.format('YYYY_MM_DD')}`;

				const data: { [key: string]: any } = this.formDetailsTravels.getData();

				for (const name in data) {
					if (name === 'km' || name === 'mealLittleTravel') {
						data[name] = data[name] || 0;
					} else {
						data[name] = data[name] ? 1 : 0;
					}
				}

				data._id = id;
				data.date = this.currentDate.format('DD/MM/YYYY');
				data.user = Utils.userID;

				await S_TravelsDetails.getInstance().save(data);

				N_save.disabled = false;

				resolve('');
			}));
		});

		N_prev_day.addEventListener2('click', () => {
			this.currentDate = this.currentDate.subtract(1, 'day');
			this.formDate.setDataByName('date', this.currentDate);
			this.initDay();
		});

		N_next_day.addEventListener2('click', () => {
			this.currentDate = this.currentDate.add(1, 'day');
			this.formDate.setDataByName('date', this.currentDate);
			this.initDay();
		});

		N_clone_day.addEventListener2('click', async () => {
			//TODO: fix quand maj core
			const data = (await Alert.prompt('Choisissez une date', '', {
				type: 'date',
				value: this.currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD')
			})) as any;

			await S_Hours.getInstance().duplicate('', { dateTo: this.currentDate.format('YYYY_MM_DD'), dateFrom: (data as Moment).format('YYYY_MM_DD') });
			await S_TravelsDetails.getInstance().duplicate('', { dateTo: this.currentDate.format('YYYY_MM_DD'), dateFrom: (data as Moment).format('YYYY_MM_DD') });

			this.initDay();
		});
	}

	private async initDay() {
		const travelDetails = await S_TravelsDetails.getInstance().getById(`${Utils.userID}_${this.currentDate.format('YYYY_MM_DD')}`) || {};

		const keys = [
			'mealLittleTravel',
			'morning',
			'midday',
			'evening',
			'night',
			'nightIDF',
			'km'
		];

		for (const name of keys) {
			if (name === 'km' || name === 'mealLittleTravel') {
				travelDetails[name] = travelDetails[name] || 0;
			} else {
				travelDetails[name] = !!travelDetails[name];
			}
		}

		this.formDetailsTravels.setData(travelDetails);

		const hours = await S_Hours.getInstance().getByDayAndUser(this.currentDate, Utils.userID);
		const hoursWeek = await S_Hours.getInstance().getByWeekAndUser(this.currentDate, Utils.userID);

		const N_hours_list = this.el.querySelector('#hours_list') as HTMLElement;

		N_hours_list.innerHTML = '';

		for (const item of hours) {
			const N_div = this.getTemplateHours(item, hours.length);

			N_hours_list.appendChild(N_div);
		}

		this.updateHoursDay(hours);
		this.updateHoursWeek(hoursWeek);
	}

	private updateHoursDay(data: { [key: string]: any }[]) {
		let tripTime = 0;
		let workTime = 0;

		let numberDayWork = 0;

		const dates = _.uniq(_.map(data, 'date'));

		for (const item of data) {
			if (item.startHours && item.endHours && !item.ignore) {
				const startDate = moment(`${item.date} ${item.startHours}`, 'DD/MM/YYYY HH:mm');
				const endDate = moment(`${item.date} ${item.endHours}`, 'DD/MM/YYYY HH:mm');

				workTime += endDate.diff(startDate, 'minute');

				if (item.isTravel) {
					tripTime += UtilsHours.calculTimeTrip(item.date, startDate, endDate);
				}
			}
		}

		for (const date of dates) {
			if (date) {
				const dateMoment = moment(date, 'DD/MM/YYYY');

				//@ts-ignore
				if ([1, 2, 3, 4, 5].indexOf(dateMoment.day()) !== -1 && !(dateMoment.isFerie() && dateMoment.getFerie() !== 'Pentecôte')) {
					numberDayWork++;
				}
			}
		}

		workTime -= tripTime;

		const N_workTime = this.el.querySelector('#workTime') as HTMLElement;
		const N_tripTime = this.el.querySelector('#tripTime') as HTMLElement;

		N_workTime.innerHTML = UtilsHours.convertMinToString(workTime) + ' / ' + numberDayWork * 7 + 'h';
		N_tripTime.innerHTML = UtilsHours.convertMinToString(tripTime);
	}

	private updateHoursWeek(data: { [key: string]: any }[]) {
		let tripTime = 0;
		let workTime = 0;

		for (const item of data) {
			if (item.startHours && item.endHours && !item.ignore) {
				const startDate = moment(`${item.date} ${item.startHours}`, 'DD/MM/YYYY HH:mm');
				const endDate = moment(`${item.date} ${item.endHours}`, 'DD/MM/YYYY HH:mm');

				workTime += endDate.diff(startDate, 'minute');

				if (item.isTravel) {
					tripTime += UtilsHours.calculTimeTrip(item.date, startDate, endDate);
				}
			}
		}

		workTime -= tripTime;

		const N_workTime_week = this.el.querySelector('#workTime_week') as HTMLElement;
		const N_tripTime_week = this.el.querySelector('#tripTime_week') as HTMLElement;

		N_workTime_week.innerHTML = UtilsHours.convertMinToString(workTime);
		N_tripTime_week.innerHTML = UtilsHours.convertMinToString(tripTime);
	}

	private getTemplateHours(item: { [key: string]: any }, number: number) {
		const N_div = document.createElement('div');
		N_div.classList.add('d-flex', 'py-1', 'align-items-center');

		const N_text = document.createElement('div');
		N_text.classList.add('w-100', 'text-truncate');

		N_text.innerHTML = `
			<div class="d-flex justify-content-between mr-3"> 
				De <span class="border border-grey rounded px-3 text-monospace">${item.startHours || '--:--'}</span> à <span class="border border-grey rounded px-3 text-monospace">${item.endHours || '--:--'}</span> 
			</div>
		`;

		if (item.description || item.location || item.project) {
			N_text.innerHTML += `<div class="text-muted">${_.compact([item.location || '', item.project || '', item.description || '']).join(' - ')}</div>`;
		}

		N_div.appendChild(N_text);

		if (item.isTravel) {
			const N_btnTravel = document.createElement('button');

			N_btnTravel.classList.add('btn', 'btn-rounded', 'btn-info', 'btn-xs', 'flex-grow-1', 'p-2');

			N_btnTravel.innerHTML = '<i class="icon icon-solid-car"></i>';

			N_btnTravel.addEventListener2('click', async () => {
				await new M_travel({
					id: item.idTravel
				}).open().then(() => {
					this.initDay();
				});
			});

			N_div.appendChild(N_btnTravel);
		} else if (item.isHolidays) {
			//ne rien faire
		} else {
			const N_btnDelete = document.createElement('button');

			N_btnDelete.classList.add('btn', 'btn-rounded', 'btn-danger', 'btn-xs', 'flex-grow-1', 'p-2');
			N_btnDelete.setAttribute('confirmation', '');

			N_btnDelete.innerHTML = '<i class="icon icon-trash-alt"></i>';

			N_btnDelete.addEventListener2('click', async () => {
				if (item._id) {
					await S_Hours.getInstance().delete(item._id);
				}

				if (number === 1) {
					await S_Hours.getInstance().save({
						week: moment(item.date, 'DD/MM/YYYY').format('WW'),
						date: item.date
					});
				}

				this.initDay();
			});

			const N_btnEdit = document.createElement('button');

			N_btnEdit.classList.add('btn', 'btn-rounded', 'btn-info', 'btn-xs', 'flex-grow-1', 'p-2');

			N_btnEdit.innerHTML = '<i class="icon icon-solid-edit"></i>';

			N_btnEdit.addEventListener2('click', async () => {
				await new M_modal(this.currentDate, item._id).open();

				this.initDay();
			});

			N_div.appendChild(N_btnEdit);
			N_div.appendChild(N_btnDelete);
		}

		return N_div;
	}

	public destructor() {
		const N_title = this.el.parentElement?.querySelector('#page-title #addon') as HTMLElement;

		N_title.innerHTML = '';
	}
}

export default HoursMobileTabs;

import { Controller, Tabs } from '@autoprog/core-client';

import C_Holidays from '../tabs/Hours.mobile.holidays';
import C_Hours from '../tabs/Hours.mobile.hours';
import C_Travels from '../tabs/Hours.mobile.travels';

import T_Holidays from '../../tpl/tabs/Hours.mobile.holidays.html';
import T_Hours from '../../tpl/tabs/Hours.mobile.hours.html';
import T_Travels from '../../tpl/tabs/Hours.mobile.travels.html';

import History from '@libs/History';

class HoursMobile extends Controller {
	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		const tabs = new Tabs({
			navElement: el.querySelector('.tabs-container') as HTMLElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'hours';

		tabs.add({
			name: 'Heures',
			id: 'hours',
			controller: C_Hours,
			default: defaultTab === 'hours',
			template: T_Hours
		});

		tabs.add({
			name: 'Déplacements',
			id: 'travels',
			controller: C_Travels,
			default: defaultTab === 'travels',
			template: T_Travels
		});

		tabs.add({
			name: 'Vacances',
			id: 'holidays',
			controller: C_Holidays,
			default: defaultTab === 'holidays',
			template: T_Holidays
		});

		let firstLoad = true;
		tabs.on('change', (item: any) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/hours/hours/${item.id}`);
			}
		});
	}

	public destructor() {

	}
}

export default HoursMobile;

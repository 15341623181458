
import '@css/vertical-accordion.scss';

export type VerticalAccordionData = {
	[id: string]: {
		element: HTMLElement,
		title: string,
		subTitle?: string;
	}
};

class VerticalAccordion extends HTMLElement {
	public static readonly tagName: string = 'ap-vertical-accordion';

	private _data: VerticalAccordionData = {};

	private tabs: { [id: string]: HTMLDivElement | null } = {};

	public async connectedCallback() {
		this.innerHTML = '';
	}

	private createAccordion() {
		this.innerHTML = '';
		this.classList.add('h-100');

		// CREATION DES ONGLETS
		this.tabs = {};

		let index = 0;
		for (const id in this._data) {
			const N_tab = document.createElement('div');
			N_tab.classList.add('h-100', 'pr-3', 'py-3');

			if (index === 0) {
				N_tab.classList.add('pl-3');
			}

			const N_tab_container = document.createElement('div');
			N_tab_container.classList.add('va-tab-container');
			N_tab_container.setAttribute('style', 'width: 60px; border-radius: 10px; ');

			const N_titles = document.createElement('div');
			N_titles.classList.add('h-100', 'd-flex', 'overflow-hidden');
			N_titles.setAttribute('style', 'writing-mode: vertical-lr');

			const N_title = document.createElement('div');
			N_title.setAttribute('id', 'tab-title');
			N_title.classList.add('va-tab-title', 'mb-auto');
			N_title.innerHTML = this._data[id].title;

			const N_subTitle = document.createElement('div');
			N_subTitle.setAttribute('id', 'tab-subtitle');
			N_subTitle.classList.add('va-tab-subtitle');

			if (this._data[id].subTitle) {
				N_subTitle.innerHTML = this._data[id].subTitle as string;
			}

			N_titles.append(N_title);
			N_titles.append(N_subTitle);

			N_tab_container.append(N_titles);

			N_tab.append(N_tab_container);

			this.tabs[id] = N_tab;

			const N_el = this._data[id].element;
			N_el.classList.add('pr-3', 'py-3');

			if (index === 0) {
				N_el.classList.add('pl-3');
			}

			(N_el.parentNode as HTMLElement).insertBefore(N_tab, N_el.nextSibling);

			index++;
		}

		this.displayFirstElement();
		this.initTabsEvents();
	}

	private displayFirstElement() {
		const firstId = Object.keys(this._data)[0];
		this.display(firstId);
	}

	/**
	 * Affiche un élement de l'accordéon
	 *
	 * Lorsqu'un élement est affiché :
	 * - on masque son onglet, on affiche son element
	 * - on affiche les autres onglets, on masque les autres elements
	 *
	 * @param id ID de l'element à afficher
	 */
	public display(id: string) {
		for (const _id in this._data) {
			if (_id === id) {
				this._data[_id].element.classList.remove('d-none');
				this.tabs[_id]?.classList.add('d-none');
			} else {
				this._data[_id].element.classList.add('d-none');
				this.tabs[_id]?.classList.remove('d-none');
			}
		}
	}

	private initTabsEvents() {
		for (const id in this._data) {
			this.tabs[id]?.addEventListener('click', () => {
				this.display(id);
			});
		}
	}

	private updateTitle(id: string) {
		const N_title = this.tabs[id]?.querySelector('#tab-title') as HTMLDivElement;
		N_title.innerHTML = this._data[id].title;
	}

	private updateSubTitle(id: string) {
		const N_subTitle = this.tabs[id]?.querySelector('#tab-subtitle') as HTMLDivElement;
		N_subTitle.innerHTML = this._data[id].subTitle as string;
	}

	/**
	 * @param id ID de l'objet donné dans le VerticalAccordionData
	 * @param title titre
	 */
	public setTabTitle(id: string, title: string) {
		if (this._data[id]) {
			this._data[id].title = title;
			this.updateTitle(id);
		}
	}

	public open(id: string) {
		this.display(id);
	}

	/**
	 * @param id ID de l'objet donné dans le VerticalAccordionData
	 * @param subTitle sous-titre
	 */
	public setTabSubTitle(id: string, subTitle: string) {
		if (this._data[id]) {
			this._data[id].subTitle = subTitle;
			this.updateSubTitle(id);
		}
	}

	public set data(data: VerticalAccordionData) {
		this._data = data;
		this.createAccordion();
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(VerticalAccordion.tagName, VerticalAccordion);
	}
}

export default VerticalAccordion;

import { Form, Modal } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/multiple.html';

import M_addCell from './AddCell';
import M_addCells from './AddCells';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import CE_ConfigProbe from '../libs/customElement/ConfigProbe';
import agUtils from '@libs/agGrid/french';

class ModalMultiple extends Modal {
	private gridOptionsCells: GridOptions = {};
	private displayCoffrets: { [key: string]: any } = {};

	private form: Form | null = null;

	constructor(box: any[], selectedBox?: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			this.initGrid();

			for (const item of box) {
				this.displayCoffrets[item._id] = item.name;
			}

			const N_configProbe = this.element.querySelector(CE_ConfigProbe.tagName) as CE_ConfigProbe;

			N_configProbe.setBox(box);

			const N_form = this.element.querySelector('#form') as HTMLFormElement;
			this.form = new Form(N_form);

			N_configProbe.postInit();

			this.form.on('**.change', () => {
				const data = this.form!.getData();

				this.gridOptionsCells.api?.forEachNode((node) => {
					if (node.isSelected()) {
						node.setData(data);
					}
				});
			});

			const N_create_cell = this.element.querySelector('#create_cell') as HTMLButtonElement;

			N_create_cell.addEventListener('click', async () => {
				const res = await new M_addCell(box, selectedBox).open();

				this.gridOptionsCells.api?.applyTransaction({
					add: res
				});
			});

			const N_create_cells = this.element.querySelector('#create_cells') as HTMLButtonElement;

			N_create_cells.addEventListener('click', async () => {
				const res = await new M_addCells(box, selectedBox).open();

				this.gridOptionsCells.api?.applyTransaction({
					add: res
				});
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const res: any[] = [];

				this.gridOptionsCells.api?.forEachNode((node) => {
					for (let i = 0; i < node.data.numberProbe; i++) {
						res.push({
							cell: node.data.cell,
							name: 'Sonde ' + (i + 1),
							...node.data.config
						});
					}

					this.resolve(res);
				});
			});
		});
	}

	private initGrid() {
		this.gridOptionsCells = agUtils.french({
			rowData: [],
			columnDefs: [
				{
					headerName: 'Cellule',
					field: 'cell'
				}, {
					headerName: 'Coffret',
					field: 'config.box',
					filter: 'agTextColumnFilter',
					floatingFilter: false,
					cellRenderer: (params) => {
						return this.displayCoffrets[params.value] || params.value;
					}
				}, {
					headerName: 'Nombre sondes',
					field: 'numberProbe'
				}, {
					headerName: '#',
					width: 80,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_delete = document.createElement('button');
						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');

						N_delete.addEventListener('click', async () => {
							params.api.applyTransaction({
								remove: [params.data]
							});
						});

						N_div.appendChild(N_delete);

						return N_div;
					}
				}
			],
			rowSelection: 'single',
			rowMultiSelectWithClick: true,
			suppressContextMenu: true,
			onSelectionChanged: (params) => {
				const N_form = this.element.querySelector('#form') as HTMLElement;
				const N_cellName = N_form.querySelector('#cellName') as HTMLSpanElement;

				N_form.style.opacity = '1';
				N_form.style.pointerEvents = 'all';

				const selectedRows = params.api.getSelectedRows();
				this.form!.setData(selectedRows[0]);
				N_cellName.innerText = selectedRows[0].cell;
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			}
		});

		const N_divSondes = document.querySelector('.grid') as HTMLElement;

		new Grid(N_divSondes, this.gridOptionsCells, { modules: AllModules });
	}
}

export default ModalMultiple;

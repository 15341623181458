
import C_database from '@js/controllers/Database';

class Travels extends C_database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Déplacement',
			database: 'travels',
			permission: 'APPS/TRAVELS'
		});
	}

	public destructor() {

	}
}

export default Travels;

import { Form, Modal } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/sonde.html';

import CE_ConfigProbe from '../libs/customElement/ConfigProbe';

class ModalSonde extends Modal {
	private sondes: any[] = [];

	private _id: string = '';

	private form: Form | null = null;

	constructor(data: { [key: string]: any }, box: any[], sondes: any[], selectedCoffretID?: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			this.sondes = sondes;

			const index = this.sondes.findIndex(item => item.box === data.box && item.cell === data.cell && item.name === data.name);
			if (index !== -1) {
				this.sondes.splice(index, 1);
			}

			this.form = new Form(this.element.querySelector('.modal-body') as HTMLFormElement);

			this._id = data._id || Date.now().toString(36);

			const N_configProbe = this.element.querySelector(CE_ConfigProbe.tagName) as CE_ConfigProbe;

			N_configProbe.setBox(box);

			const oldValue = sondes[sondes.length - 1] || {};

			this.form.setData({
				cell: data.cell || oldValue.cell || 'C01',
				sensorType: data.sensorType || oldValue.sensorType || 'PT1000',
				sensorNumber: data.sensorNumber || oldValue.sensorNumber || '7',
				box: selectedCoffretID || data.box || oldValue.box || box[0]._id || '',
				name: data.name || this.getNewName(data.cell || oldValue.cell || 'C01'),
				wiringType: data.wiringType || '1'
			});

			const N_title = this.element.querySelector('.modal-title') as HTMLElement;

			if (Object.keys(data).length) {
				N_title.innerHTML = 'Modification sonde';
			} else {
				N_title.innerHTML = 'Nouvelle sonde';
			}

			this.init();

			N_configProbe.postInit();
		});
	}

	private getNewName(cell: string) {
		let index = 1;

		for (const item of this.sondes) {
			if (item.cell === cell && item.name) {
				index++;
			}
		}

		return 'Sonde ' + index;
	}

	private init() {
		const N_close = this.element.querySelector('.close') as HTMLButtonElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		const N_cell = this.element.querySelector('[name="cell"]') as HTMLInputElement;
		const N_name = this.element.querySelector('[name="name"]') as HTMLInputElement;

		const N_alert_name_already_used = this.element.querySelector('#alert_name_already_used') as HTMLElement;
		N_alert_name_already_used.classList.add('d-none');

		N_cell.addEventListener2('change', () => {
			N_name!.value = this.getNewName(N_cell.value);
		});

		N_save.addEventListener2('click', () => {
			const data = this.form!.getData();

			N_alert_name_already_used.classList.add('d-none');

			const index = this.sondes.findIndex(item => item.box === data.box && item.cell === data.cell && item.name === data.name);

			if (index === -1) {
				data._id = this._id;

				this.resolve(data);
			} else {
				N_alert_name_already_used.classList.remove('d-none');
			}
		});

		N_close.addEventListener2('click', () => {
			this.reject();
		});
	}
}

export default ModalSonde;

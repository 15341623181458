import { Form, Modal, toaster } from '@autoprog/core-client';

import Settings from '@libs/Settings';

import _ from 'lodash';

import moment from 'moment';

import SignaturePad from 'signature_pad';

import M_Holidays from './Holidays';

import S_Holidays from '@services/Hours/HolidaysService';
import S_Users from '@services/User/UserService';

import T_modal from '../../tpl/modals/validateHolidays.html';

class ValidateHolidays extends Modal {
	private type: { [key: string]: any } = {
		0: 'Congés',
		1: 'Recup',
		2: 'Absence'
	};

	constructor(id: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			let data = await S_Holidays.getInstance().getById(id);

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			this.createHeader(data);

			const form = new Form(N_form);

			data.infos.state = '1';

			form.setData(data);

			const N_canvas = this.element.querySelector('#signature') as HTMLCanvasElement;

			const signaturePad = new SignaturePad(N_canvas, {
				backgroundColor: 'rgb(255, 255, 255)'
			});

			const N_clearSignature = this.element.querySelector('#clear_signature') as HTMLButtonElement;

			N_clearSignature.addEventListener2('click', () => {
				signaturePad.clear();
			});

			const N_edit = this.element.querySelector('#edit') as HTMLButtonElement;

			N_edit.addEventListener2('click', () => {
				new M_Holidays(id, 'edit').open().then(async () => {
					data = await S_Holidays.getInstance().getById(id);

					this.createHeader(data);

					data.infos.state = '1';

					form.setData(data);
				});
			});

			const N_save = this.element.querySelector('#save') as HTMLElement;

			N_save.addEventListener2('click', async () => {
				const signature = signaturePad.toDataURL('image/svg+xml');

				const tmp = form.getData() as any;

				data.validate = {
					signature: atob(signature.split(',')[1])
				};

				data.infos.comment = tmp.infos.comment;
				data.infos.state = tmp.infos.state;

				await S_Holidays.getInstance().save(data, { type: 'validate' });

				this.resolve();
			});

			const N_importSignature = this.element.querySelector('#import_signature') as HTMLButtonElement;

			N_importSignature.addEventListener2('click', () => {
				const signature = Settings.getInstance().get('SIGNATURE') as any;

				if (signature) {
					const ratioX = N_canvas.width / signature.width;
					const ratioY = N_canvas.height / signature.height;

					for (const item of signature.data) {
						for (const point of item.points) {
							point.x *= ratioX;
							point.y *= ratioY;
						}
					}

					signaturePad.fromData(signature.data);
				} else {
					toaster.error('Signature introuvable');
				}
			});

			const N_btnCalendar = this.element.querySelector('#btn_calendar') as HTMLButtonElement;

			N_btnCalendar.addEventListener2('click', () => {
				window.open(`/#module/hours/calendar?day=${moment(data.infos.startDate, 'x').format('DD_MM_YYYY')}`, '_blank');
			});
		});
	}

	private genereItemHTML(text: string, value: string) {
		return `
			<div class="d-flex mb-2">
				<div class="page-card-label mr-1 text-nowrap">${text} :</div>
				<div class="page-card-value">${value}</div>
			</div>
		`;
	}

	private async createHeader(data: { [key: string]: any }) {
		const N_resume = this.element.querySelector('#resume') as HTMLElement;

		data = _.cloneDeep(data);

		data.infos.startDate = moment(data.infos.startDate, 'x');
		data.infos.endDate = moment(data.infos.endDate, 'x');

		const user = await S_Users.getInstance().getDisplayRefByID(data.infos.user);

		N_resume.innerHTML = '';

		N_resume.innerHTML += this.genereItemHTML('Type', this.type[data.infos.type as string]);
		N_resume.innerHTML += this.genereItemHTML('Demandeur', user);
		N_resume.innerHTML += this.genereItemHTML('Date', moment(data.request.date, 'x').format('DD/MM/YYYY HH:mm'));

		//Congés
		if (data.infos.type === '0') {
			N_resume.innerHTML += this.genereItemHTML('A partir du', data.infos.startDate.format('DD/MM/YYYY'));
			N_resume.innerHTML += this.genereItemHTML('Reprise le', data.infos.endDate.format('DD/MM/YYYY'));
		}

		//Recup'
		if (data.infos.type === '1') {
			N_resume.innerHTML += this.genereItemHTML('A partir du', `${data.infos.startDate.format('DD/MM/YYYY')} ${data.infos.startHours}`);
			N_resume.innerHTML += this.genereItemHTML('Reprise le', `${data.infos.endDate.format('DD/MM/YYYY')} ${data.infos.endHours}`);
		}

		//Absence
		if (data.infos.type === '2') {
			N_resume.innerHTML += this.genereItemHTML('A partir du', `${data.infos.startDate.format('DD/MM/YYYY')} ${data.infos.startHours}`);
			N_resume.innerHTML += this.genereItemHTML('Reprise le', `${data.infos.endDate.format('DD/MM/YYYY')} ${data.infos.endHours}`);
			N_resume.innerHTML += this.genereItemHTML('Motif', data.infos.description);
		}

		N_resume.innerHTML += this.genereItemHTML('Commentaire', data.request.comment || 'Aucun commentaire');

		//calendrier
		const N_calendar = this.element.querySelector('#calendar tbody') as HTMLElement;

		N_calendar.innerHTML = `
            <tr>
                <td></td>
                <td class="font-weight-bold">Lun.</td>
                <td class="font-weight-bold">Mar.</td>
                <td class="font-weight-bold">Mer.</td>
                <td class="font-weight-bold">Jeu.</td>
                <td class="font-weight-bold">Ven.</td>
                <td class="font-weight-bold">Sam.</td>
                <td class="font-weight-bold">Dim.</td>
            </tr>
        `;

		const startMonth = data.infos.startDate.clone().startOf('month');
		const endMonth = data.infos.endDate.clone().endOf('month');

		const currentDate = startMonth.clone().isoWeekday(1);
		const endDate = endMonth.clone().isoWeekday(7);

		const tableDate: { [key: string]: any[] } = {};

		while (currentDate.isBefore(endDate)) {
			const date = currentDate.format('DD/MM');
			const key = currentDate.clone().startOf('week').format('YYYY_WW');

			tableDate[key] = tableDate[key] || [];

			tableDate[key].push({
				date,
				week: currentDate.format('WW'),
				//@ts-ignore
				isFerie: currentDate.isFerie(),
				isSunday: currentDate.day() === 0,
				isInMonth: (startMonth.isSameOrBefore(currentDate, 'day') && currentDate.isSameOrBefore(endMonth, 'day')),
				isInHoliday: (
					(data.infos.startDate.isSameOrBefore(currentDate, 'day') && currentDate.isBefore(data.infos.endDate, 'day')) ||
					(data.infos.startDate.isSame(data.infos.endDate, 'day') && data.infos.startDate.isSame(currentDate, 'day'))
				)
			});

			currentDate.add(1, 'day');
		}

		const weekArray = Object.keys(tableDate).sort();

		const rows: string[] = [];

		for (const week of weekArray) {
			const cells: string[] = [];

			for (const item of tableDate[week]) {
				let classes = '';

				if (item.isInHoliday) {
					classes = 'bg-blue-300';
				}

				if (item.isSunday) {
					classes = 'bg-red-300';
				}

				if (item.isFerie) {
					classes = 'bg-orange-300';
				}

				if (!item.isInMonth) {
					classes = ' text-grey-300';
				}

				cells.push(`<td class="${classes}">${item.date}</td>`);
			}

			rows.push(`
				<tr>
					<td class="font-weight-bold">S. ${tableDate[week][0].week}</td>
					${cells.join('')}
                </tr>
            `);
		}

		N_calendar.innerHTML = rows.join('');
	}
}

export default ValidateHolidays;

import GenericService from '../GenericService';

class HolidaysService extends GenericService {
	private static instance: HolidaysService | null = null;

	constructor() {
		super('holidays');
	}

	public static getInstance(): HolidaysService {
		if (!HolidaysService.instance) {
			HolidaysService.instance = new HolidaysService();
		}

		return HolidaysService.instance;
	}

	public getLastByDayAndUser(user: string = '') {
		return this.get_function('getLastByDayAndUser', { user });
	}
}

export default HolidaysService;

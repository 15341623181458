import GenericService from '../GenericService';

class SiloboxHistoryService extends GenericService {
	private static instance: SiloboxHistoryService | null = null;

	constructor() {
		super('silobox_history');
	}

	public static getInstance(): SiloboxHistoryService {
		if (!SiloboxHistoryService.instance) {
			SiloboxHistoryService.instance = new SiloboxHistoryService();
		}

		return SiloboxHistoryService.instance;
	}
}

export default SiloboxHistoryService;

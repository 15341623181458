import { LoggedUser, LoginModule, Router, global, toaster } from '@autoprog/core-client';

import T_RFID from '@tpl/blocks/RFID_login.svg.html';

import axios from 'axios';
import moment from 'moment';

import S_Badges from '@services/BadgesService';
import SettingsApps from './Settings';

class Electron {
	private static instance: Electron;

	private _currentBadge = '';

	private timeoutDisconnect: any = null;

	private enableTimeout: boolean = true;

	private constructor() {
		//@ts-ignore
		const { ipcRenderer } = window.require('electron');

		const loggedUser = LoggedUser.getInstance();

		global.NO_NAVBAR = true;
		global.NO_ASIDE = true;

		const defaultAside = LoginModule.loginPageAside;

		LoginModule.loginPageAside = `<div class="w-100 d-flex" style="padding:50px; z-index:999999">${T_RFID}</div>`;

		ipcRenderer.on('config', (event: string, data: any) => {
			if (data['new-badge']) {
				global.NO_NAVBAR = false;
				global.NO_ASIDE = false;

				LoginModule.loginPageAside = defaultAside;
			}
		});

		const router = Router.getInstance();

		let intervalHours: any = null;

		router.on('route.render.end', (route: any) => {
			clearInterval(intervalHours);

			if (route.url === 'login') {
				const N_container = document.querySelector('#login-page') as HTMLElement;

				const N_hours = document.createElement('div');

				N_hours.style.top = '10px';
				N_hours.style.left = '20px';
				N_hours.style.fontSize = '50px';
				N_hours.classList.add('position-absolute');

				N_hours.id = 'hours-login';

				N_hours.innerHTML = moment().format('DD/MM/YYYY HH:mm:ss');

				N_container.appendChild(N_hours);

				intervalHours = setInterval(() => {
					const N_hours = document.querySelector('#hours-login') as HTMLElement;

					N_hours.innerHTML = moment().format('DD/MM/YYYY HH:mm:ss');
				}, 1000);
			}
		});

		ipcRenderer.on('new-badge', (event: string, data: any) => {
			const N_input = document.querySelector('[name="badge"]') as HTMLInputElement;

			if (N_input && data !== '0000000000') {
				N_input.value = data;
			}
		});

		let oldValue = '';

		ipcRenderer.on('badge', async (event: string, data: any) => {
			if (data !== '0000000000' && this._currentBadge !== data && oldValue !== data) {
				await this.checkLogin(data);
			}

			if (this._currentBadge === data && oldValue === '0000000000') {
				loggedUser.logout();
			}

			oldValue = data;
		});

		document.body.addEventListener('touchstart', () => {
			if (loggedUser.isLogged) {
				clearTimeout(this.timeoutDisconnect);

				if (this.enableTimeout) {
					this.timeoutDisconnect = setTimeout(() => {
						loggedUser.logout();
					}, 10 * 60 * 1000);
				}
			}
		});

		document.body.addEventListener('mousemove', () => {
			if (loggedUser.isLogged) {
				clearTimeout(this.timeoutDisconnect);

				if (this.enableTimeout) {
					this.timeoutDisconnect = setTimeout(() => {
						loggedUser.logout();
					}, 10 * 60 * 1000);
				}
			}
		});

		loggedUser.on('login', () => {
			ipcRenderer.send('login', true);

			this.enableTimeoutDisconnect();
		});

		loggedUser.on('logout', () => {
			this._currentBadge = '';
			this.disabledTimeoutDisconnect();
			ipcRenderer.send('logout', true);
		});
	}

	public get timeoutEnable() {
		return this.enableTimeout;
	}

	public get currentBadge() {
		return this._currentBadge;
	}

	public disabledTimeoutDisconnect() {
		this.timeoutDisconnect && clearTimeout(this.timeoutDisconnect);

		this.timeoutDisconnect = null;

		this.enableTimeout = false;
	}

	public enableTimeoutDisconnect() {
		const loggedUser = LoggedUser.getInstance();

		this.timeoutDisconnect = setTimeout(() => {
			loggedUser.logout();
		}, 10 * 60 * 1000);

		this.enableTimeout = true;
	}

	private async checkLogin(badge: string) {
		const N_invalid = document.querySelector('#invalid') as HTMLElement;
		const N_valid = document.querySelector('#valid') as HTMLElement;
		const N_hand = document.querySelector('#hand') as HTMLElement;

		const loggedUser = LoggedUser.getInstance();

		if (loggedUser.isLogged) {
			this._currentBadge = '';
			loggedUser.logout();
		}

		let enableResetTimeout = true;

		if (badge) {
			try {
				const data = await S_Badges.getInstance().login(badge);

				if (data.logged) {
					const res = await axios.get('/currentUser');

					if (res.data && !res.data.err) {
						N_hand && N_hand.classList.add('d-none');
						N_valid && N_valid.classList.add('active');

						enableResetTimeout = false;

						setTimeout(async () => {
							this._currentBadge = badge;

							loggedUser.setData(res.data);
							await SettingsApps.getInstance().load();

							Router.getInstance().navigate('#module/apps/home');
						}, 1000);
					} else {
						N_hand && N_hand.classList.add('d-none');
						N_invalid && N_invalid.classList.add('active');

						throw new Error('get_user_data');
					}
				} else {
					N_hand && N_hand.classList.add('d-none');
					N_invalid && N_invalid.classList.add('active');

					throw new Error('wrong_user_or_password');
				}
			} catch (e) {
				N_hand && N_hand.classList.add('d-none');
				N_invalid && N_invalid.classList.add('active');

				if ((e as any).response.data === 'UNKNOWN_BADGES') {
					toaster.error('Badge inconnu', badge);
				}
			}
		} else {
			N_hand && N_hand.classList.add('d-none');
			N_invalid && N_invalid.classList.add('active');
		}

		enableResetTimeout && setTimeout(() => {
			N_hand && N_hand.classList.remove('d-none');
			N_invalid && N_invalid.classList.remove('active');
			N_valid && N_valid.classList.remove('active');
		}, 500);
	}

	public static getInstance() {
		if (!Electron.instance) {
			Electron.instance = new Electron();
		}

		return Electron.instance;
	}
}

export default Electron;

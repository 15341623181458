
// CORE
import { LoggedUser, Router, global } from '@autoprog/core-client';

// NODE_MODULES
import h from 'hyperscript';

// LIBS
import Utils from '@libs/utils/Utils';

// CONTROLLERS
import ControllerPageID from '@js/controllers/ControllerPageID';

// SERVICES
import S_Companies from '@services/CompaniesService';
import S_Users from '@services/User/UserService';

// STYLE
import '@css/company-select.scss';

// TYPES
import Company from '@type/company/company';
import User from '@type/user';

class CompanySelect extends HTMLElement {
	/**
	 * Le nom de tag HTML du Custom Element
	 */
	public static readonly tagName: string = 'ap-company-select';

	/**
	 * L'utilisateur connecté
	 */
	private user: User | undefined = undefined;

	/**
	 * Liste des sociétés de l'utilisateur connecté
	 */
	private userCompanies: Company[] = [];

	/**
	 * La société sélectionnée
	 */
	private selectedCompany: Company | undefined = undefined;

	/**
	 * Les elements HTML du template
	 */
	private N_NoCompany: HTMLDivElement;
	private N_SingleCompany: HTMLDivElement;
	private N_CompanyDropdown: HTMLDivElement;

	private N_SingleCompanyIcon: HTMLDivElement;
	private N_SingleCompanyName: HTMLDivElement;

	private N_CompanyDropdownToggle: HTMLDivElement;
	private N_SelectedCompanyName: HTMLDivElement;
	private N_CompanyDropdownMenu: HTMLDivElement;

	public constructor() {
		super();

		this.N_SingleCompanyIcon = h('div#single-company-icon.company-icon',
			h('i.icon.icon-solid-building.mr-1')
		);
		this.N_SingleCompanyName = h('div#single-company-name');

		this.N_CompanyDropdownToggle = h('div#company-dropdown-toggle.dropdown-toggle',
			{ role: 'button', 'data-toggle': 'dropdown', 'aria-haspopup': 'true', 'aria-expanded': 'false' },
			h('div#selected-company-icon.company-icon', h('i.icon.icon-solid-building.mr-1')));
		this.N_SelectedCompanyName = h('div#selected-company-name');

		this.N_CompanyDropdownMenu = h('div.dropdown-menu', { 'aria-labelledby': 'company-dropdown-toggle' });

		this.N_NoCompany = h('div#no-company.custom-nav-select', 'Société');
		this.N_SingleCompany = h('div#single-company.custom-nav-select.d-none');
		this.N_CompanyDropdown = h('div#company-dropdown.custom-nav-select.dropdown.show.d-none');
	}

	/**
	 * Fonction appelée au moment ou l'élement est inséré dans le DOM
	 */
	public async connectedCallback() {
		this.appendChild(this.N_NoCompany);
		this.appendChild(this.N_SingleCompany);
		this.appendChild(this.N_CompanyDropdown);

		this.N_SingleCompany.appendChild(this.N_SingleCompanyIcon);
		this.N_SingleCompany.appendChild(this.N_SingleCompanyName);

		this.N_CompanyDropdownToggle.appendChild(this.N_SelectedCompanyName);

		this.N_CompanyDropdown.appendChild(this.N_CompanyDropdownToggle);
		this.N_CompanyDropdown.appendChild(this.N_CompanyDropdownMenu);

		this.init();

		this.setLoginEvent();
		this.setLogoutEvent();
	}

	/**
	 * Fonction d'initialisation principale
	 */
	private async init() {
		await LoggedUser.getInstance().whenLogged();
		await this.fetchData();
		this.initElementDisplay();
	}

	/**
	 * Récupération des données nécessaires
	 */
	private async fetchData() {
		this.userCompanies = [];

		this.user = await S_Users.getInstance().getById(Utils.userID) as User;

		const selectedCompanyID = Utils.companyID;

		for (const companyID of this.user?.companies || []) {
			const companyData = await S_Companies.getInstance().getById(companyID) as Company;
			this.userCompanies.push(companyData);

			if (selectedCompanyID && selectedCompanyID === companyID) {
				this.selectedCompany = companyData;
			}
		}
	}

	/**
	 * Définit la société sélectionnée pour l'utilisateur actuel dans le localStorage
	 * @param userID l'id de l'utilisateur connecté
	 * @param companyID l'id de la société sélectionnée
	 */
	private setCompanyUserSelection(userID: string, companyID: string) {
		const companyUsersSelectionJSON = localStorage.getItem('companyUsersSelectionJSON');
		const companyUserSelectionObject: { [key: string]: string } = companyUsersSelectionJSON ? JSON.parse(companyUsersSelectionJSON) : {};
		companyUserSelectionObject[userID] = companyID;

		localStorage.setItem('companyUsersSelectionJSON', JSON.stringify(companyUserSelectionObject));
	}

	/**
	 * Initialisation de l'affichage de l'element en fonction du nombre de société
	 */
	private initElementDisplay() {
		if (global.IS_MOBILE) {
			if (this.userCompanies.length === 0) {
				this.displayNoCompany();
			} else if (this.userCompanies.length === 1) {
				this.displaySingleCompany();
			} else if (this.userCompanies.length > 1) {
				this.displayMultipleCompanies();
			}
		} else {
			if (this.userCompanies.length === 0) {
				this.displayNoCompany();
			} else if (this.userCompanies.length === 1) {
				this.displaySingleCompany();
			} else if (this.userCompanies.length > 1) {
				this.displayMultipleCompanies();
			}
		}
	}

	/**
	 * Affiche l'element dans le cas où il n'y a pas de société
	 */
	private displayNoCompany() {
		this.N_SingleCompany.classList.add('d-none');
		this.N_CompanyDropdown.classList.add('d-none');
		this.N_NoCompany.classList.remove('d-none');
	}

	/**
	 * Affiche l'element dans le cas d'une seule société
	 */
	private displaySingleCompany() {
		this.selectedCompany = this.userCompanies[0];

		this.setCompanyUserSelection(this.user!._id, this.selectedCompany._id);
		localStorage.setItem('selectedCompanyID', this.selectedCompany._id);

		this.N_SingleCompanyName.innerHTML = this.selectedCompany.name;

		this.N_NoCompany.classList.add('d-none');
		this.N_CompanyDropdown.classList.add('d-none');
		this.N_SingleCompany.classList.remove('d-none');
	}

	/**
	 * Affiche l'element dans le cas de plusieurs sociétés
	 */
	private displayMultipleCompanies() {
		if (!this.selectedCompany) {
			this.selectedCompany = this.userCompanies[0];
		}

		this.updateSelectedCompany(this.selectedCompany);

		this.N_CompanyDropdownMenu.innerHTML = '';

		for (const company of this.userCompanies) {
			const N_DropdownItem = document.createElement('div');

			N_DropdownItem.classList.add('dropdown-item');
			N_DropdownItem.innerHTML = company.name;
			N_DropdownItem.dataset.id = company._id;

			N_DropdownItem.addEventListener('click', () => {
				this.selectCompanyEvent(company);
			});

			this.N_CompanyDropdownMenu.append(N_DropdownItem);
		}

		this.updateActiveDropdownItem();

		this.N_NoCompany.classList.add('d-none');
		this.N_SingleCompany.classList.add('d-none');
		this.N_CompanyDropdown.classList.remove('d-none');
	}

	/**
	 * Évènement levé à la sélection d'une société
	 * @param company la société à sélectionner
	 */
	private selectCompanyEvent(company: Company) {
		if (!this.selectedCompany || this.selectedCompany._id !== company._id) {
			//@ts-ignore
			const pageInstance = Router.getInstance().controllerInst;

			if (pageInstance instanceof ControllerPageID) {
				//@ts-ignore
				(pageInstance as ControllerPageID).return().then(() => {
					this.updateSelectedCompany(company);
				}).catch(() => {
				});
			} else {
				this.updateSelectedCompany(company);

				Router.getInstance().reload();
			}
		}
	}

	/**
	 * Met à jour la société sélectionnée
	 * @param company la société sélectionnée
	 */
	private updateSelectedCompany(company: Company) {
		this.selectedCompany = company;

		this.setCompanyUserSelection(this.user!._id, this.selectedCompany._id);
		localStorage.setItem('selectedCompanyID', this.selectedCompany._id);

		this.N_SelectedCompanyName.innerHTML = this.selectedCompany.name;

		this.updateActiveDropdownItem();
	}

	private updateActiveDropdownItem() {
		const dropdownItems = this.N_CompanyDropdownMenu.querySelectorAll('.dropdown-item') as NodeListOf<HTMLElement>;

		for (const dropdownItem of dropdownItems) {
			if (dropdownItem.dataset.id === this.selectedCompany?._id) {
				dropdownItem.classList.add('active');
			} else {
				dropdownItem.classList.remove('active');
			}
		}
	}

	/**
	 * Définit un évenement pour recharger le custom element à la connexion
	 */
	private setLoginEvent() {
		LoggedUser.getInstance().on('login', () => {
			this.reload();
		});
	}

	/**
	 * Définit un évenement pour supprimer la société selectionnée en cas de déconnexion
	 */
	private setLogoutEvent() {
		LoggedUser.getInstance().on('logout', () => {
			localStorage.removeItem('selectedCompanyID');
		});
	}

	/**
	 * Recharge le custom element
	 */
	public reload() {
		this.init();
	}

	/**
	 * Enregistrement du Custom Element
	 */
	public static register() {
		customElements.define(CompanySelect.tagName, CompanySelect);
	}
}

export default CompanySelect;

import Modal from '@libs/Modal';

import moment, { Moment } from 'moment';

import T_modal from '../../tpl/modals/hoursMobileEdit.html';

import S_Hours from '@services/Hours/HoursService';

class HoursMobileEdit extends Modal {
	constructor(date: Moment, id?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			let data : {[key:string] : string} = {};

			const N_modal_title = this.element.querySelector('.modal-title') as HTMLElement;

			if (id) {
				data = await S_Hours.getInstance().getById(id);
				N_modal_title.innerHTML = 'Modification Heure';
			} else {
				N_modal_title.innerHTML = 'Ajout Heure';
			}

			const N_date = this.element.querySelector('#date') as HTMLInputElement;
			const N_description = this.element.querySelector('#description') as HTMLInputElement;
			const N_location = this.element.querySelector('#location') as HTMLInputElement;
			const N_project = this.element.querySelector('#project') as HTMLInputElement;
			const N_startHours = this.element.querySelector('#startHours') as HTMLInputElement;
			const N_endHours = this.element.querySelector('#endHours') as HTMLInputElement;

			N_date.value = date.format('YYYY-MM-DD');
			N_description.value = data.description || '';
			N_location.value = data.location || '';
			N_project.value = data.project || '';
			N_startHours.value = data.startHours || '';
			N_endHours.value = data.endHours || '';

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener2('click', async () => {
				const res: { [key: string]: any } = {
					_id: id,
					date: moment(N_date.value, 'YYYY-MM-DD').format('DD/MM/YYYY'),
					description: N_description.value,
					location: N_location.value,
					project: N_project.value,
					startHours: N_startHours.value,
					endHours: N_endHours.value
				};

				await S_Hours.getInstance().save(res);

				this.resolve();
			});
		});
	}
}

export default HoursMobileEdit;

import { Form, Modal, toaster } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/addCell.html';

import CE_ConfigProbe from '../libs/customElement/ConfigProbe';

class AddCell extends Modal {
	constructor(box: any[], selectedBox?: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_configProbe = this.element.querySelector(CE_ConfigProbe.tagName) as CE_ConfigProbe;

			N_configProbe.setBox(box);

			const form = new Form(this.element.querySelector('#form') as HTMLFormElement);

			form.setData({
				numberProbe: 1,
				config: {
					box: selectedBox || box[0]._id
				}
			});

			N_configProbe.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const data = form.getData();

				const res: any[] = [];
				const cellName = data.cell_name as string || '';

				if (cellName.trim() !== '') {
					res.push({
						cell: cellName,
						...data
					});

					this.resolve(res);
				} else {
					toaster.error('Saisie incorrecte');
				}
			});
		});
	}
}

export default AddCell;


class HoursDescriptionEditor {
	private value: string = '';
	private params: any;
	private eGui: HTMLElement;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('w-100', 'ag-input-text-wrapper');
	}

	public getGui() {
		return this.eGui;
	}

	private humanizeHours(value: string) {
		value = value.trim();

		value = value.replace('h', ':');
		value = value.replace('H', ':');

		let chunkValue = value.split(':');

		if (chunkValue.length === 1) {
			//1400 => 14:00
			if (value.length === 4) {
				value = `${value[0]}${value[1]}:${value[2]}${value[3]}`;
			}

			//400 => 4:00
			if (value.length === 3) {
				value = `${value[0]}:${value[1]}${value[2]}`;
			}

			//14 => 14:00 || 4 => 4:00
			if (value.length === 2 || value.length === 1) {
				value = `${value}:00`;
			}
		}

		chunkValue = value.split(':');

		if (chunkValue.length === 2) {
			value = ('00' + chunkValue[0]).slice(-2) + ':' + ('00' + chunkValue[1]).slice(-2);
		}

		return value;
	}

	public getValue() {
		return this.humanizeHours(this.value || '');
	}

	public afterGuiAttached() {
		const input = this.eGui.querySelector('input');

		if (input) {
			input.focus();

			if (this.params.charPress) {
				input.value = this.params.charPress;

				this.value = input.value;
			}
		}
	}

	public isPopup() {
		return false;
	}

	public init(params: any) {
		this.params = params;

		this.value = params.value;
		this.eGui.innerHTML = '<input type="text" class="w-100 form-control-sm p-0" placeholder="HH:MM" style="height: 26px;">';

		const input = this.eGui.querySelector('input') as HTMLInputElement;

		input.value = params.value || '';

		input.addEventListener2('input', () => {
			this.value = input.value;
		});

		input.addEventListener2('blur', () => {
			params.api.stopEditing();
		});
	}

	public destroy() {

	}
}

export default HoursDescriptionEditor;

// CORE
import { Alert, Router, SlimScroll, utils } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
import T_box from '../../tpl/blocks/box';

// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';

import OpenDocuments from '@libs/customElement/OpenDocuments';
import agUtils from '@libs/agGrid/french';

// UTILS

// MODAL
import M_Coffret from '../modals/modalCoffret';
import M_IP from '../modals/modalIP';
import M_Infos from '../modals/modalInfos';
import M_Sonde from '../modals/modalSonde';
import M_multiple from '../modals/modalMultiple';

// CUSTOM_ELEMENT

// SERVICE
import S_Config_Thermo from '@services/ConfigThermoService';

class ConfigThermoCtrl extends ControllerPageID {
	private configData: { [key: string]: any };

	private boxData: { [key: string]: any }[];

	private site: string;

	private gridOptionsSondes: GridOptions = {};
	private IPaddresses: { [key: string]: any };

	private selectedBox: string | null = null;

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = ConfigThermoCtrl.options || {};

		ConfigThermoCtrl.options = {};

		this.configData = {};
		this.boxData = [];
		this.site = '';
		this.IPaddresses = {};

		this.routeReturn = 'module/config_thermo';

		this.init('config_thermo', id);

		if (!this.id) {
			this.openEditTitle(true);
		}
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		ConfigThermoCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'config_thermo');
			Router.getInstance().navigate(`/module/config_thermo/edit?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/config_thermo/edit');
		}
	}

	private openEditTitle(firstOpen = false) {
		new M_Infos({
			site: this.site
		}).open().then((data) => {
			this.site = data.site;

			this.updateTitle();

			if (firstOpen) {
				this.addCoffret(firstOpen);
			} else {
				this.updateSaveButton();
			}
		}).catch(() => {
			if (firstOpen) {
				this.return();
			}
		});
	}

	private initSondes() {
		this.gridOptionsSondes = agUtils.french<GridOptions>({
			rowData: [],
			columnDefs: [
				{
					headerName: 'Coffret',
					field: 'box',
					filter: 'agTextColumnFilter',
					floatingFilter: false,
					cellRenderer: (params) => {
						return this.boxData.find(item => item._id === params.value)?.name || params.value;
					}
				}, {
					headerName: 'Cellule',
					field: 'cell'
				}, {
					headerName: 'Nom',
					field: 'name'
				}, {
					headerName: 'Type de capteur',
					field: 'sensorType',
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: ['PT100', 'PT1000', 'CTN-1', 'CTN-2', 'CTN-3', 'CTN-4']
					}
				}, {
					headerName: 'Nombre de capteurs',
					field: 'sensorNumber'
				}, {
					headerName: 'Type de câblage',
					field: 'wiringType',
					cellRenderer: (params) => {
						if (params.node.group !== true) {
							if (params.value === '1') {
								return 'Point Commun';
							} else if (params.value === '2') {
								return '2 fils';
							}
							return '???';
						}
						return '';
					}
				}, {
					headerName: '#',
					width: 100,
					editable: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_edit = document.createElement('button');
						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

						N_edit.setAttribute('tooltip', 'Editer');

						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

						N_edit.addEventListener('click', async () => {
							const data = await new M_Sonde(params.data, this.boxData, this.getDataSondes()).open();

							params.node.setData(data);

							this.getConfig();
						});

						const N_delete = document.createElement('button');
						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');

						N_delete.addEventListener('click', async () => {
							params.api.applyTransaction({
								remove: [params.data]
							});

							this.getConfig();
						});

						N_div.appendChild(N_edit);
						N_div.appendChild(N_delete);

						return N_div;
					}
				}
			],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			suppressContextMenu: true
		});

		const N_divSondes = this.el.querySelector('#sondes') as HTMLElement;

		new Grid(N_divSondes, this.gridOptionsSondes, { modules: AllModules });
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initSondes();

		this.initSlimScroll();
		const data = await this.getData();

		this.setData(data);

		this.postInit();

		this.updateButtons();

		const N_editTitle = this.el.querySelector('#editTitle') as HTMLButtonElement;

		N_editTitle.addEventListener('click', () => {
			this.openEditTitle();
		});
	}

	protected initSlimScroll() {
		const N_div = this.el.querySelector('#box-container') as HTMLElement;

		new SlimScroll(N_div);
	}

	private async addCoffret(firstOpen = false) {
		new M_Coffret({}, this.boxData).open().then((data) => {
			this.boxData.push(data);

			this.updateButtons();

			this.initRenderBox();
			this.getConfig();
		}).catch(() => {
			if (firstOpen) {
				this.return();
			}
		});
	}

	protected initButton() {
		super.initButton();

		const N_add_coffret = this.el.querySelector('#add_coffret') as HTMLButtonElement;
		const N_add_sonde = this.el.querySelector('#add_sonde') as HTMLButtonElement;
		const N_ip = this.el.querySelector('#ip') as HTMLButtonElement;
		const N_add_cells = this.el.querySelector('#add_cells') as HTMLButtonElement;

		N_add_coffret.addEventListener('click', async () => {
			this.addCoffret();
		});

		N_add_sonde.addEventListener('click', async () => {
			const data = await new M_Sonde({}, this.boxData, this.getDataSondes(), this.selectedBox || undefined).open();

			this.gridOptionsSondes.api?.applyTransaction({
				add: [data]
			});

			this.getConfig();
		});

		N_ip.addEventListener('click', async () => {
			this.IPaddresses = await new M_IP(this.boxData, this.configData, this.IPaddresses || {}).open();

			this.updateSaveButton();
		});

		N_add_cells.addEventListener('click', () => {
			new M_multiple(this.boxData, this.selectedBox || undefined).open().then((data) => {
				this.gridOptionsSondes.api?.applyTransaction({
					add: data
				});
				this.getConfig();
			});
		});
	}

	private templateRenderBox(item: { [key: string]: any }) {
		let N_div = this.el.querySelector(`#box-container [data-id="${item._id}"]`) as HTMLElement;

		if (!N_div) {
			N_div = document.createElement('div');
			N_div.classList.add('box');
			N_div.dataset.id = item._id;
		}

		N_div.innerHTML = T_box(item);

		N_div.addEventListener('click', () => {
			const instance = this.gridOptionsSondes.api?.getFilterInstance('box');

			const N_title_sonde = this.el.querySelector('#box-selected') as HTMLElement;

			if (item._id === this.selectedBox) {
				N_title_sonde.innerHTML = 'Tout';
				instance!.setModel(null);
				this.selectedBox = null;
			} else {
				N_title_sonde.innerHTML = 'Coffret ' + item.name;
				instance!.setModel({
					filter: item._id,
					filterType: 'text',
					type: 'equals'
				});

				this.selectedBox = item._id;
			}

			this.updateActiveBox();

			this.gridOptionsSondes.api?.onFilterChanged();
		});

		const N_delete = N_div.querySelector('#delete') as HTMLElement;
		const N_duplicate = N_div.querySelector('#duplicate') as HTMLElement;
		const N_edit = N_div.querySelector('#edit') as HTMLElement;

		N_delete.addEventListener('click', () => {
			Alert.confirm('Confirmation', 'Voulez vous supprimer le coffret et ses sondes associées ?').then(async () => {
				const toDelete: any[] = [];

				this.gridOptionsSondes.api?.forEachNode((node) => {
					if (node.data.box === item._id) {
						toDelete.push(node.data);
					}
				});

				this.gridOptionsSondes.api?.applyTransaction({
					remove: toDelete
				});

				this.boxData = this.boxData.filter(box => box._id !== item._id);

				N_div.remove();

				if (!this.boxData.length) {
					const N_box_container = this.el.querySelector('#box-container') as HTMLElement;
					N_box_container.innerHTML = '<div class="d-flex h-100 justify-content-center align-items-center text-muted">Veuillez ajouter un coffret</div>';
				}

				this.getConfig();
			});
		});

		N_duplicate.addEventListener('click', async () => {
			const data = await new M_Coffret(item, this.boxData, true).open();

			const newData = {
				...data,
				_id: Date.now().toString(36)
			};

			this.boxData.push(newData);

			const sondes = this.getDataSondes();

			const newSondes: { [key: string]: any }[] = [];

			for (const sonde of sondes) {
				if (sonde.box === item._id) {
					newSondes.push({
						...sonde,
						box: newData._id
					});
				}
			}

			this.gridOptionsSondes.api?.applyTransaction({
				add: newSondes
			});

			this.gridOptionsSondes.api?.refreshCells({ force: true });

			this.initRenderBox();
			await this.getConfig();
			this.updateProbesNumber();
		});

		N_edit.addEventListener('click', async () => {
			const data = await new M_Coffret(item, this.boxData).open();

			const index = this.boxData.findIndex(item => item._id === data._id);
			this.boxData[index] = data;

			this.gridOptionsSondes.api?.refreshCells({ force: true });

			this.initRenderBox();
			this.getConfig();
		});

		return N_div;
	}

	private updateButtons() {
		const N_add_sonde = this.el.querySelector('#add_sonde') as HTMLButtonElement;
		const N_add_cells = this.el.querySelector('#add_cells') as HTMLButtonElement;

		N_add_sonde.disabled = this.boxData.length === 0;
		N_add_cells.disabled = this.boxData.length === 0;
	}

	private updateActiveBox() {
		const N_div = this.el.querySelectorAll('#box-container [data-id]') as NodeListOf<HTMLElement>;

		N_div.forEach((N_el) => {
			if (N_el.dataset.id === this.selectedBox) {
				N_el.classList.add('active');
			} else {
				N_el.classList.remove('active');
			}
		});
	}

	private updateProbesNumber() {
		const N_box_container = this.el.querySelector('#box-container') as HTMLElement;

		for (const id in this.configData) {
			const N_div = N_box_container.querySelector(`[data-id="${id}"]`) as HTMLElement;

			const N_nb_tete = N_div.querySelector('#nb-tete') as HTMLElement;
			const N_nb_ext = N_div.querySelector('#nb-ext') as HTMLElement;
			const N_nb_sonde = N_div.querySelector('#nb-sonde') as HTMLElement;

			N_nb_tete.innerHTML = this.configData[id]?.modulesTete;
			N_nb_ext.innerHTML = this.configData[id]?.modulesExt;
			N_nb_sonde.innerHTML = this.configData[id]?.sondes.length;
		}
	}

	protected initTitle() {
		const N_title = this.el.querySelector('#title') as HTMLElement;
		N_title.innerHTML = this.site;
	}

	protected async updateTitle() {
		const N_title = this.el.querySelector('#title') as HTMLElement;
		N_title.innerHTML = this.site;
	}

	private initRenderBox() {
		const N_box_container = this.el.querySelector('#box-container') as HTMLElement;

		N_box_container.innerHTML = '';
		if (this.boxData.length) {
			for (const item of this.boxData) {
				const N_div = this.templateRenderBox(item);

				N_box_container.appendChild(N_div);
			}
		} else {
			N_box_container.innerHTML = '<div class="d-flex h-100 justify-content-center align-items-center text-muted">Veuillez ajouter un coffret</div>';
		}

		this.updateActiveBox();
	}

	protected async setData(data: DataServer) {
		this.boxData = data.data.boxData || [];

		this.site = data.data.title || '';

		this.initTitle();

		this.gridOptionsSondes.api?.setRowData(data.data.probeData);

		this.initRenderBox();

		this.getConfig(false);
	}

	private async getConfig(updateButton = true) {
		this.configData = await (this.service as S_Config_Thermo).getConfig(this.saveData);
		this.updateProbesNumber();

		if (updateButton) {
			this.updateSaveButton();
		}
	}

	private getDataSondes() {
		const res: { [key: string]: any }[] = [];

		this.gridOptionsSondes.api?.forEachNode((node) => {
			res.push(node.data);
		});

		return res;
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.boxData = this.boxData;
		newData.probeData = this.getDataSondes();
		newData.title = this.site || 'Listing Thermométrie';
		newData.IPaddresses = this.IPaddresses;

		return newData;
	}
}

export default ConfigThermoCtrl;

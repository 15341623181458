import { Color, Controller } from '@autoprog/core-client';

import _ from 'lodash';
import moment from 'moment';

import Calendar from 'tui-calendar';

import S_Holidays from '@services/Hours/HolidaysService';
import S_User from '@services/User/UserService';

import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

class CalendarCtrl extends Controller {
	private el: HTMLElement;
	private calendar: Calendar;

	private users: { [key: string]: any }[] = [];

	private colors: string[] = [
		'#f44336', // 'red'
		'#e91e63', // 'pink'
		'#9c27b0', // 'purple'
		'#673ab7', // 'deep-purple'
		'#3f51b5', // 'indigo'
		'#2196f3', // 'blue'
		'#03a9f4', // 'light-blue'
		'#00bcd4', // 'cyan'
		'#009688', // 'teal'
		'#4caf50', // 'green'
		'#8bc34a', // 'light-green'
		'#cddc39', // 'lime'
		'#ffeb3b', // 'yellow'
		'#ffc107', // 'amber'
		'#ff9800', // 'orange'
		'#ff5722' // 'deep-orange'
	];

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.calendar = new Calendar('#calendar', {
			defaultView: 'month',
			taskView: false,
			useDetailPopup: true,
			useCreationPopup: false,
			isReadOnly: true,
			usageStatistics: false,
			template: {
				popupEdit: () => {
					return 'Modifier';
				},
				popupDelete: () => {
					return 'Suppression';
				},
				alldayTitle: () => {
					return '<div class="w-100 text-right">Journée entière</div>';
				},
				monthGridHeaderExceed: (hiddenSchedules) => {
					return `<span class="tui-full-calendar-weekday-grid-more-schedules">${hiddenSchedules} autre${hiddenSchedules > 1 ? 's' : ''}</span>`;
				},
				timegridDisplayPrimayTime: (time) => {
					return time.hour + ': 00';
				},
				timegridDisplayPrimaryTime: (time) => {
					return time.hour + ': 00';
				}

			},
			month: {
				daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				startDayOfWeek: 1,
				narrowWeekend: true
			},
			week: {
				daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				startDayOfWeek: 1,
				narrowWeekend: true
			}
		});

		this.initButton();

		this.setStaticCalendar();

		this.getData();

		this.eventStaticButton();

		this.calendar.render(true);

		const params = this.getGETParams();

		if (params.day) {
			const date = moment(params.day, 'DD_MM_YYYY');
			const N_month = this.el.querySelector('#month') as HTMLElement;
			N_month.innerHTML = _.upperFirst(date.format('MMMM YYYY'));

			this.calendar.setDate(date.toDate());
		}
	}

	private initButton() {
		const N_month = this.el.querySelector('#month') as HTMLElement;

		const N_prev = this.el.querySelector('#prev') as HTMLButtonElement;
		const N_now = this.el.querySelector('#now') as HTMLButtonElement;
		const N_next = this.el.querySelector('#next') as HTMLButtonElement;

		N_month.innerHTML = _.upperFirst(moment().format('MMMM YYYY'));

		N_prev.addEventListener2('click', () => {
			this.calendar.prev();
			N_month.innerHTML = _.upperFirst(moment(this.calendar.getDate().toDate()).format('MMMM YYYY'));
		});

		N_now.addEventListener2('click', () => {
			this.calendar.today();
			N_month.innerHTML = _.upperFirst(moment(this.calendar.getDate().toDate()).format('MMMM YYYY'));
		});

		N_next.addEventListener2('click', () => {
			this.calendar.next();
			N_month.innerHTML = _.upperFirst(moment(this.calendar.getDate().toDate()).format('MMMM YYYY'));
		});

		const N_view = this.el.querySelector('#view') as HTMLSelectElement;

		N_view.addEventListener2('change', () => {
			this.calendar.changeView(N_view.value);
		});
	}

	private setStaticCalendar() {
		const N_calendarList = this.el.querySelector('#calendarList') as HTMLElement;

		N_calendarList.innerHTML += `
			<li class="pt-2">
				<button class="btn btn-info btn-sm" id="select_all">Sélection tout</button>
				<button class="btn btn-info btn-sm" id="unselect_all">Désélectionner tout</button>
			</li>
            <li class="font-weight-bold my-2">
                Personnels
            </li>
        `;

		this.calendar.setCalendarColor('schedule', {
			color: Color.getContrastedColor('#5d4037').toHex(),
			bgColor: '#5d4037',
			borderColor: '#5d4037',
			dragBgColor: '#5d4037'
		});
	}

	private eventStaticButton() {
		const N_calendarList = this.el.querySelector('#calendarList') as HTMLElement;

		const N_select_all = N_calendarList.querySelector('#select_all') as HTMLButtonElement;

		N_select_all.addEventListener2('click', () => {
			const N_itemList = N_calendarList.querySelectorAll('.calendars-item') as NodeListOf<HTMLElement>;

			for (const N_item of N_itemList) {
				const N_input = N_item.querySelector('input') as HTMLInputElement;
				const N_span = N_item.querySelector('span') as HTMLInputElement;

				N_input.checked = true;

				N_span.style.backgroundColor = N_span.style.borderColor;

				this.calendar.toggleSchedules(N_input.dataset.id || '', !N_input.checked, true);
			}
		});

		const N_unselect_all = this.el.querySelector('#unselect_all') as HTMLButtonElement;

		N_unselect_all.addEventListener2('click', () => {
			const N_itemList = N_calendarList.querySelectorAll('.calendars-item') as NodeListOf<HTMLElement>;
			for (const N_item of N_itemList) {
				const N_input = N_item.querySelector('input') as HTMLInputElement;
				const N_span = N_item.querySelector('span') as HTMLInputElement;

				N_input.checked = false;

				N_span.style.backgroundColor = 'transparent';

				this.calendar.toggleSchedules(N_input.dataset.id || '', !N_input.checked, true);
			}
		});
	}

	private async getData() {
		this.users = await S_User.getInstance().getAll();

		this.users = _.sortBy(this.users, ['lastname', 'firstname']);

		const N_calendarList = this.el.querySelector('#calendarList') as HTMLElement;

		let index = 0;
		for (const user of this.users) {
			if (!user._id.startsWith('_') && user._id !== '1') {
				const N_li = document.createElement('li');

				N_li.classList.add('calendars-item', 'cursor-pointer');

				N_li.innerHTML = `
					<input type="checkbox" class="tui-full-calendar-checkbox-round" checked data-id="user_${user._id}">
					<span style="border-color: ${this.colors[index]}; background-color: ${this.colors[index]};" id="color"> </span>
					<span> ${S_User.getInstance().displayRefByData(user)} </span>
				`;

				this.calendar.setCalendarColor(`user_${user._id}`, {
					color: Color.getContrastedColor(this.colors[index]).toHex(),
					bgColor: this.colors[index],
					borderColor: this.colors[index],
					dragBgColor: this.colors[index]
				});

				N_calendarList.appendChild(N_li);

				index++;

				index = index % this.colors.length;
			}
		}

		const N_itemList = N_calendarList.querySelectorAll('.calendars-item') as NodeListOf<HTMLElement>;

		for (const N_item of N_itemList) {
			N_item.addEventListener2('click', () => {
				const N_input = N_item.querySelector('input') as HTMLInputElement;
				const N_span = N_item.querySelector('span') as HTMLInputElement;

				N_input.checked = !N_input.checked;

				if (N_input.checked) {
					N_span.style.backgroundColor = N_span.style.borderColor;
				} else {
					N_span.style.backgroundColor = 'transparent';
				}

				this.calendar.toggleSchedules(N_input.dataset.id || '', !N_input.checked, true);
			});
		}

		this.getHolidays();
	}

	private async getHolidays() {
		const data = await S_Holidays.getInstance().getAll();

		const type: { [key: string]: string } = {
			0: 'Congés',
			1: 'Recup',
			2: 'Absence',
			3: 'Arrêt Maladie'
		};

		const events: any[] = [];

		for (const item of data) {
			if (!item._id.startsWith('_') && !item.deleted && !item.cancel) {
				let start = moment(item.infos.startDate, 'x');
				let end = moment(item.infos.endDate, 'x');

				start = moment(`${start.format('DD_MM_YYYY')}_${item.infos.startHours}`, 'DD_MM_YYYY_HH:mm');
				end = moment(`${end.format('DD_MM_YYYY')}_${item.infos.endHours}`, 'DD_MM_YYYY_HH:mm');

				const user = _.find(this.users, { _id: item.infos.user }) || { firstname: '', lastname: '' } as any;

				let isAllDay = true;
				let title = `${type[item.infos.type]} ${user.firstname} ${user.lastname[0]}.`;

				if (item.infos.startHours !== '00:00' || item.infos.endHours !== '00:00') {
					title += `, ${item.infos.startHours} - ${item.infos.endHours}`;

					if (start.isSame(end, 'days')) {
						isAllDay = false;
					}
				}

				if (item.infos.endHours === '00:00') {
					end = end.subtract(12, 'hours');
				}

				const event: { [key: string]: any } = {
					id: `holidays_${item._id}`,
					calendarId: `user_${item.infos.user}`,
					isAllDay,
					title,
					category: 'time',
					start: start.toDate(),
					end: end.toDate()
				};

				if (!item.validate && item.infos.type !== '3') {
					event.bgColor = 'transparent';
					event.color = '#000000';
				}

				events.push(event);
			}
		}

		this.calendar.createSchedules(events);
	}

	private getGETParams() {
		const getStr = location.href.split('?')[1];
		const params: { [key: string]: any } = {};

		if (getStr) {
			const items = getStr.split('&');

			for (const item of items) {
				const [key, value] = item.split('=');
				params[key] = decodeURIComponent(value);
			}
		}

		return params;
	}

	public destructor() {

	}
}

export default CalendarCtrl;

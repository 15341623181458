
import C_Database from '@js/controllers/Database';

class StatsTab extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Interface',
			database: 'silobox_stats',
			permission: 'INTERFACE'
		});
	}

	protected getRowHeight(params: any) {
		return 42 * params.data.maxLine;
	}
}

export default StatsTab;

import GenericService from '../GenericService';

import moment, { Moment } from 'moment';

class HoursService extends GenericService {
	private static instance: HoursService | null = null;

	constructor() {
		super('hours');
	}

	public static getInstance(): HoursService {
		if (!HoursService.instance) {
			HoursService.instance = new HoursService();
		}

		return HoursService.instance;
	}

	public getByDayAndUser(date: Moment = moment(), user: string = '') {
		return this.get_function('getByDayAndUser', { date: date.valueOf().toString(), user });
	}

	public getByWeekAndUser(date: Moment = moment(), user: string = '') {
		return this.get_function('getByWeekAndUser', { date: date.valueOf().toString(), user });
	}
}

export default HoursService;

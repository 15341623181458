
import { Form, Modal, toaster } from '@autoprog/core-client';

import '../../css/qrcode.css';
import T_modal from '../../tpl/modals/codes.html';

import S_Qr_Code from '@services/QrCode/QrCodeCodeService';

import _ from 'lodash';

class CodesModal extends Modal {
	private sitesSelected: Map<string, any> = new Map();

	private form: Form | null = null;
	private id: string = '';

	constructor(id: string = '') {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id = id;

		this.on('opened', async () => {
			const N_form = this.element.querySelector('.modal-body') as HTMLFormElement;

			this.form = new Form(N_form);

			this.form.on('*.change', () => {
				this.generateQrcodePreview();
			});

			this.getData();
		});
	}

	private async getData() {
		const { data } = await S_Qr_Code.getInstance().getDataToModal(this.id);

		this.form!.setData(data.data);

		for (const site of data.data.sites) {
			this.sitesSelected.set(site.id, {
				id: site.id,
				text: site.text,
				customer: site.customer
			});
		}

		this.initSites(data.customers, data.sites);

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener2('click', () => {
			this.save();
		});

		this.initSearch(this.element, 'search-customers', 'customers');
		this.initSearch(this.element, 'search-sites', 'sites');
		this.initSearch(this.element, 'search-select-sites', 'select-sites');

		this.generateQrcodePreview();
	}

	private async initSites(customers: any[], sites: any[]) {
		const N_container = this.element.querySelector('#container-sites') as HTMLElement;

		const N_Customer = N_container.querySelector('#customers') as HTMLSelectElement;
		const N_Sites = N_container.querySelector('#sites') as HTMLSelectElement;
		const N_select = N_container.querySelector('#select-sites') as HTMLSelectElement;

		const customersDisplay: { [key: string]: any } = {};

		for (const customer of customers) {
			N_Customer.appendChild(new Option(customer.text, customer.id));
			customersDisplay[customer.id] = customer.text;
		}

		const groupByCustomer = _.groupBy(sites, 'customer');

		N_Customer.addEventListener2('change', () => {
			const options = N_Customer.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;

			N_Sites.innerHTML = '';

			for (const option of options) {
				if (option.selected) {
					const N_optGroup = document.createElement('optgroup');
					N_optGroup.label = customersDisplay[option.value];
					N_Sites.appendChild(N_optGroup);

					const N_option = new Option('Tous les sites', `all_site_${option.value}`);
					N_option.dataset.customer = option.value;
					N_Sites.appendChild(N_option);

					for (const site of (groupByCustomer[option.value] || [])) {
						const N_option = new Option(site.text, site.id);
						N_option.dataset.customer = site.customer;
						N_Sites.appendChild(N_option);
					}
				}
			}
		});

		const N_add = N_container.querySelector('#add') as HTMLButtonElement;
		const N_delete = N_container.querySelector('#delete') as HTMLButtonElement;

		N_add.addEventListener2('click', () => {
			const options = N_Sites.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;

			for (const option of options) {
				if (option.selected) {
					this.sitesSelected.set(option.value, {
						id: option.value,
						text: option.innerHTML,
						customer: option.dataset.customer
					});
				}
			}

			this.displaySelectedSites(customersDisplay);
		});

		N_delete.addEventListener2('click', () => {
			const options = N_select.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
			for (const option of options) {
				if (option.selected) {
					this.sitesSelected.delete(option.value);
				}
			}

			this.displaySelectedSites(customersDisplay);
		});

		this.displaySelectedSites(customersDisplay);
	}

	private displaySelectedSites(customersDisplay: { [key: string]: any }) {
		const N_container = this.element.querySelector('#container-sites') as HTMLElement;
		const N_select = N_container.querySelector('#select-sites') as HTMLSelectElement;

		N_select.innerHTML = '';

		const valuesByCustomer = _.groupBy([...this.sitesSelected.values()], 'customer') as { [key: string]: any[] };

		for (const customer in valuesByCustomer) {
			const N_optGroup = document.createElement('optgroup');
			N_optGroup.label = customersDisplay[customer];
			N_select.appendChild(N_optGroup);

			for (const site of (valuesByCustomer[customer] || [])) {
				const N_option = new Option(site.text, site.id);
				N_option.dataset.customer = customer;

				N_select.appendChild(new Option(site.text, site.id));
			}
		}
	}

	private async save() {
		const data = this.form!.getData();

		const newData = {
			_id: this.id,
			codeEncloser: data.codeEncloser as string,
			description: data.description as string,
			name: data.name as string,
			notes: data.notes as string,
			tags: data.tags as Array<string>,
			urlDocument: data.urlDocument as string,
			urlRedirection: data.urlRedirection as string,
			sites: [...this.sitesSelected.keys()]
		};

		await S_Qr_Code.getInstance().save(newData);
		toaster.success('Qrcode crée');

		this.resolve();
	}

	private async generateQrcodePreview(): Promise<void> {
		const N_previewQrcode = this.element.querySelector('#preview-qrcode') as HTMLImageElement;

		const data = this.form!.getData();

		N_previewQrcode.src = await S_Qr_Code.getInstance().getRouteGenerateQrCodeByData(data.urlRedirection as string, data.notes as string, data.codeEncloser as string);
	}

	private initSearch(parent: HTMLElement, idSearch: string, idSelect: string) {
		const N_input = parent.querySelector(`#${idSearch}`) as HTMLInputElement;
		const N_select = parent.querySelector(`#${idSelect}`) as HTMLElement;

		N_input.addEventListener2('input', () => {
			const options = N_select.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
			for (const option of options) {
				if (option.innerHTML.toLowerCase().includes(N_input.value.toLowerCase())) {
					option.classList.remove('d-none');
				} else {
					option.classList.add('d-none');
				}
			}
		});
	}
}

export default CodesModal;

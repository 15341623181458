import { Modal } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/ip.html';

class ModalIP extends Modal {
	private N_inputs: HTMLInputElement[] = [];

	constructor(box: any[], config: { [key: string]: any }, data: { [key: string]: any }) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_IPs = this.element.querySelector('#list_ip') as HTMLElement;

			this.N_inputs = [];

			N_IPs.innerHTML = '';

			for (const item of box) {
				for (let i = 0; i < config[item._id]?.modulesTete; i++) {
					const N_div = document.createElement('div');

					N_div.innerHTML = `
						<label class="pt-3">IP du module de tête du coffret ${item.name} : </label>
						<input name="${item._id}" data-index='${i}' class="form-control" autocomplete="off" value="${data[item._id] ? data[item._id][i] || '' : ''}">
					`;

					this.N_inputs.push(N_div.querySelector('[name]') as HTMLInputElement);

					N_IPs.appendChild(N_div);
				}
			}

			this.init();
		});
	}

	private init() {
		const N_close = this.element.querySelector('.close') as HTMLButtonElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			const data: { [key: string]: any } = {};

			for (const N_input of this.N_inputs) {
				data[N_input.name] = data[N_input.name] || [];
				data[N_input.name][N_input.dataset.index || ''] = N_input.value || '';
			}

			this.resolve(data);
		});

		N_close.addEventListener('click', () => {
			this.reject();
		});
	}
}

export default ModalIP;

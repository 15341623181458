import GenericService from '../GenericService';

class SiloboxStatsService extends GenericService {
	private static instance: SiloboxStatsService | null = null;

	constructor() {
		super('silobox_stats');
	}

	public static getInstance(): SiloboxStatsService {
		if (!SiloboxStatsService.instance) {
			SiloboxStatsService.instance = new SiloboxStatsService();
		}

		return SiloboxStatsService.instance;
	}
}

export default SiloboxStatsService;

import { Form, Modal } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/infos.html';

import CE_Select2 from '@libs/customElement/Select2';

class ModalInfos extends Modal {
	private form: Form | null = null;

	constructor(data: { [key: string]: any }) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			this.init();

			this.form = new Form(this.element.querySelector('form')!);

			this.form.setData(data);

			this.postInit();
		});
	}

	private init() {
		const N_close = this.element.querySelector('.close') as HTMLButtonElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			const data = this.form?.getData();
			this.resolve(data);
		});

		N_close.addEventListener('click', () => {
			this.reject();
		});
	}

	private postInit() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;

		N_customer.postInit();
	}
}

export default ModalInfos;


//TEMPLATES
import T_QuoteInformations from '../../../tpl/customElement/quoteInformations.html';

class QuoteInformations extends HTMLElement {
	public static readonly tagName: string = 'ap-quote-informations';

	public async connectedCallback() {
		this.innerHTML = T_QuoteInformations;
	}

	public static register() {
		customElements.define(QuoteInformations.tagName, QuoteInformations);
	}
}

export default QuoteInformations;

import { Modal } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/coffret.html';

import _ from 'lodash';

class ModalCoffret extends Modal {
	private _id: string = '';

	private box: any[] = [];

	private N_name: HTMLInputElement | null = null;
	private N_description: HTMLInputElement | null = null;
	private duplicate: boolean | undefined;

	constructor(data: { [key: string]: any }, box: any[], duplicate?: boolean) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			box = _.cloneDeep(box);

			this._id = data._id || Date.now().toString(36);

			this.N_name = this.element.querySelector('[name="name"]') as HTMLInputElement;
			this.N_description = this.element.querySelector('[name="description"]') as HTMLInputElement;

			this.N_name.value = data.name || '';
			this.N_description.value = data.description || '';

			this.duplicate = duplicate;

			const N_title = this.element.querySelector('.modal-title') as HTMLElement;

			const index = box.findIndex(item => item._id === data._id);

			if (index !== -1 && !this.duplicate) {
				box.splice(index, 1);
				N_title.innerHTML = 'Modification coffret';
			} else {
				N_title.innerHTML = 'Nouveau coffret';
			}

			this.box = box;

			this.init();
		});
	}

	private init() {
		const N_alert_name_required = this.element.querySelector('#alert_name_required') as HTMLElement;
		const N_alert_name_already_used = this.element.querySelector('#alert_name_already_used') as HTMLElement;

		N_alert_name_required.classList.add('d-none');
		N_alert_name_already_used.classList.add('d-none');

		const N_close = this.element.querySelector('.close') as HTMLButtonElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener2('click', () => {
			const data = {
				_id: this._id,
				name: this.N_name!.value,
				description: this.N_description!.value
			};

			N_alert_name_required.classList.add('d-none');
			N_alert_name_already_used.classList.add('d-none');

			if (data.name) {
				const index = this.box.findIndex(item => item.name === data.name);

				if (index === -1) {
					this.resolve(data);
				} else {
					N_alert_name_already_used.classList.remove('d-none');
				}
			} else {
				N_alert_name_required.classList.remove('d-none');
			}
		});

		N_close.addEventListener2('click', () => {
			this.reject();
		});
	}
}

export default ModalCoffret;

import { Form, Modal, toaster } from '@autoprog/core-client';

import T_Modal from '../../tpl/modals/addCells.html';

import CE_ConfigProbe from '../libs/customElement/ConfigProbe';

class AddCells extends Modal {
	constructor(box: any[], selectedBox?: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_configProbe = this.element.querySelector(CE_ConfigProbe.tagName) as CE_ConfigProbe;

			N_configProbe.setBox(box);

			const form = new Form(this.element.querySelector('#form') as HTMLFormElement);

			form.setData({
				numberProbe: 1,
				config: {
					box: selectedBox || box[0]._id
				}
			});

			N_configProbe.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const data = form.getData();

				const res: any[] = [];

				const startCell = data.cell_start as number;
				const endCell = data.cell_end as number;
				const prefix = data.cell_prefix as string;

				if (startCell <= endCell) {
					for (let i = startCell; i <= endCell; i++) {
						res.push({
							cell: (prefix || '') + i.toString().padStart(2, '0'),
							...data
						});
					}

					this.resolve(res);
				} else {
					toaster.error('Saisie incorrect');
				}
			});
		});
	}
}

export default AddCells;

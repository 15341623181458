import GenericService from './GenericService';

class Badges extends GenericService {
	private static instance: Badges | null = null;

	constructor() {
		super('badges');
	}

	public static getInstance(): Badges {
		if (!Badges.instance) {
			Badges.instance = new Badges();
		}

		return Badges.instance;
	}

	public async login(badge: string) {
		return this.post_function('login', { badge });
	}
}

export default Badges;
